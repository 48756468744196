import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import { getSessionData } from "../../../utils/storageUtils"
import { MdManageSearch } from "react-icons/md";
import { CiBarcode } from "react-icons/ci";
import ModalConsultaPallet from "./components/modalConsultaPallet";
import ModalConsultaLocal from "./components/modalConsultaLocal";
import ModalConsultaBarras from "./components/modalConsultaBarras";

function MenuAcoes() {
    const [viewModalPallet, setViewModalPallet] = useState<any>()
    const [viewModalLocal, setViewModalLocal] = useState<any>()
    const [viewModalBarras, setViewModalBarras] = useState<any>()
    
    const navigate = useNavigate()

    function fecharModal() {
        setViewModalPallet(false)
        setViewModalLocal(false)
        setViewModalBarras(false)
    }

    return (
        <>
            <Card className="mt-4">
                <div className="container mt-3 mx-auto text-center mb-3 mt-md-4">
                    <button
                        type="button"
                        className="btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                        onClick={() => setViewModalPallet(true)}
                    >
                        <MdManageSearch className="me-1 mb-1" size={25}/>
                        Consultar U.A.
                    </button>
                    <button
                        type="button"
                        className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                        onClick={() => setViewModalLocal(true)}
                    >
                        <MdManageSearch className="me-1 mb-1" size={25}/>
                        Consultar Local
                    </button>
                    <button
                        type="button"
                        className="mt-2 mt-md-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                        onClick={() => setViewModalBarras(true)}
                    >
                        <CiBarcode className="me-1 mb-1" size={25}/>
                        Consultar Barra
                    </button>
                </div>
            </Card>

            <ModalConsultaPallet 
            isOpen={viewModalPallet}
            fecharModal={fecharModal}
            />

            <ModalConsultaLocal 
            isOpen={viewModalLocal}
            fecharModal={fecharModal}
            />

            <ModalConsultaBarras 
            isOpen={viewModalBarras}
            fecharModal={fecharModal}
            />
        </>
    )
}

export default MenuAcoes