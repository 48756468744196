import { Card, Row } from "react-bootstrap"
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import Tabela from "../../../../components/Formularios/Table";
import { useEffect, useRef, useState } from "react";
import Icon from '@mdi/react';
import { mdiWeight } from '@mdi/js';
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { getSessionData } from "../../../../utils/storageUtils";
import { toast } from "react-toastify";
import { IoIosAddCircle } from "react-icons/io";
import { mdiPlusCircle } from '@mdi/js';
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";


function PesagemUA() {
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(false)
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)
    const [seqUA, setSeqUA] = useState<any>()
    const [dados, setDados] = useState([])
    const [pesoLiq, setPesoLiq] = useState<any>(0)
    const [status, setStatus] = useState<any>()
    const [pesoTotal, setPesoTotal] = useState<any>()
    const [tara, setTara] = useState<any>()
    const [pesoAtual, setPesoAtual] = useState<any>()

    const params = useParams()
    const OS = params.OS

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")
    const usuarioLogado = getSessionData("NomeUsuario")

    const pbRef = useRef(null)
    const taraRef = useRef(null)
    const plRef = useRef(null)
    const statusRef = useRef(null)
    const btnRef = useRef(null)
    const seqUARef = useRef(null)


    //Funções para controle de ações da tabela
    const editarUsuario = () => { };
    const excluirUsuario = async (dados: any) => {
        await showConfirmationDialog(
            "Excluir peso de U.A.",
            `Tem certeza que deseja excluir a pesagem\n ${dados.ID_UA}?`,
            "question"
        ).then(function (resposta) {
            if (resposta.confirmed) {
                axios.delete(`${process.env.REACT_APP_API_BASE_URL}/excluir/pesagem/${dados.ID_UA}?idFilial=${idFilial}`, {
                    headers: {
                        Authorization: token
                    }
                }).then(function (resposta) {
                    toast.success(resposta.data)
                    carregaGrid()
                    atualizaPesoAtual()
                }).catch(function (erro) {
                    toast.error("Um erro inesperado aconteceu ao tentar excluir equipamento")
                })
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(
                    `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                );
            }
        })
    };
    const visualizarTabelaOS = () => { };

    const navigate = useNavigate()

    //Criação das colunas da tabela
    const colunas: ITabela[] = [
        { titulo: "Seq U.A", acesso: "ID_UA" },
        { titulo: "Peso Líq", acesso: "PESO" },
        { titulo: "O.S", acesso: "OS" },
        { titulo: "Peso Bruto", acesso: "PESO_BR" },
        { titulo: "Tara", acesso: "TARA" }
    ];

    function fecharPesagem(ref: any) {
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/fechar/pesagem?idFilial=${idFilial}`, {
            OS,
            pesoAtual
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setStatus(resposta.data.status)
            toast.success(resposta.data.message)
            setPesoTotal("")
            setTara("")
            setPesoLiq(0)
            navigate(-1)
            ref.current.focus()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                if (status == 406) {
                    toast.error(erro.response.data)
                } else if (status == 424) {
                    toast.error(erro.response.data.message)
                    setStatus(erro.response.data.status)
                }
            }
        })
    }

    function inserirPesoUA(ref: any) {
        if (!pesoTotal ||
            !tara ||
            !seqUA
        ) {
            toast.info("Preencha o peso e tara para prosseguir")
            return;
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/inserir/peso/UA?idFilial=${idFilial}`, {
            pesoTotal,
            tara,
            pesoLiq,
            seqUA,
            OS,
            usuarioLogado
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            setStatus(resposta.data.status)
            atualizaPesoAtual()
            carregaGrid()
            consultaSeqEStatus()
            setPesoTotal("")
            setTara("")
            setPesoLiq(0)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                toast.error(erro.response.data)
                ref.current.focus()
            }
        })
    }

    //Essa função está preenchendo o grid, setando o seqUA e preenchendo o status atual 
    function carregaGrid() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carregar/grid/${OS}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
        })
    }

    function atualizaPesoAtual() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/atualizar/pesoAtual/${OS}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setPesoAtual(resposta.data[0].peso_total)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                console.info(erro.response.data)
                setPesoAtual(0)
            }
        })
    }

    function consultaSeqEStatus() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/infos/${OS}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            if (resposta.data.seqUA[0].seqUA == null) {
                setSeqUA(1)
            } else {
                setSeqUA(resposta.data.seqUA[0].seqUA)
            }
            setStatus(resposta.data.status[0].MSG_STATUS)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                console.info(erro.response.data)
                setSeqUA(1)
            }
        })
    }

    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    const handleModificaPL = (ref: any) => {
        if (parseFloat(pesoTotal) <= parseFloat(tara)) {
            toast.info("O peso total não pode ser igual ou menor que a tara")
            ref.current.focus()
        } else {
            setPesoLiq((pesoTotal - tara).toFixed(2))
        }
    }

    useEffect(() => {
        carregaGrid()
        consultaSeqEStatus()
        atualizaPesoAtual()
    }, [])

    return (
        <>
            <Card className="mt-2">
                <Card.Body>
                    <Row>
                        <div className="col-2">
                            <IoMdArrowBack
                                style={{ color: 'DarkOrange' }}
                                size={28}
                                onClick={() => navigate(-1)}
                            />
                        </div>
                    </Row>
                    <Row className="align-items-center">
                        <div className="col-6 form-floating mt-2">
                            <input
                                type="number"
                                value={seqUA}
                                autoFocus
                                className="form-control bordasInferiorInput"
                                ref={seqUARef}
                                onKeyDown={(event) => handleKeyDown(event, pbRef)}
                                onChange={(event) => {
                                    setSeqUA(event.target.value)
                                }}
                                disabled
                            />
                            <label className="form-label">SEQ U.A.</label>
                        </div>

                        <div className="col-6 form-floating mt-2">
                            <input
                                typeof="number"
                                value={pesoTotal}
                                className="form-control bordasInferiorInput"
                                ref={pbRef}
                                onKeyDown={(event) => handleKeyDown(event, taraRef)}
                                onChange={(event) => {
                                    const valor = event.target.value.replace(',', '.');
                                    setPesoTotal(valor)
                                }}
                            />
                            <label className="form-label">PESO TOTAL</label>
                        </div>
                    </Row>

                    <Row className="align-items-center">
                        <div className="col-6 form-floating mt-2">
                            <input
                                typeof="number"
                                value={tara}
                                className="form-control bordasInferiorInput"
                                ref={taraRef}
                                onKeyDown={(event) => {
                                    handleKeyDown(event, btnRef)
                                }}
                                onChange={(event) => {
                                    const valor = event.target.value.replace(',', '.');
                                    setTara(valor)
                                }}
                                onBlur={() => handleModificaPL(taraRef)}
                            />
                            <label className="form-label">TARA</label>
                        </div>

                        <div className="col-6 form-floating mt-2">
                            <input
                                value={pesoLiq}
                                className="form-control bordasInferiorInput"
                                onKeyDown={(event) => handleKeyDown(event, btnRef)}
                                disabled
                            />
                            <label className="form-label">PESO LÍQ</label>
                        </div>
                    </Row>

                    <Row className="align-items-center">
                        <div className="col-6 form-floating mt-2">
                            <input
                                type="text"
                                value={status}
                                className={`font-weight-bold form-control bordasInferiorInput ${status === 'APROVADO' ? 'text-aprovado' : status === 'PENDENTE' ? 'text-pendente' : 'text-reprovado'}`}
                                ref={statusRef}
                                disabled
                            />
                            <label className="form-label">STATUS</label>
                        </div>

                        <div className="col-6 form-floating mt-2">
                            <input
                                type="text"
                                value={pesoAtual}
                                className="font-weight-bold form-control bordasInferiorInput"
                                // ref={statusRef}
                                disabled
                            />
                            <label className="form-label">PESO ATUAL</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-6 mt-3">
                            <button
                                type="button"
                                className="w-100 btn btn-cancelar btn-block rounded"
                                ref={btnRef}
                                onClick={() => inserirPesoUA(seqUARef)}
                            >
                                <Icon className="me-1 mb-1" path={mdiPlusCircle} size={0.8} />
                                Inserir peso U.A.
                            </button>
                        </div>

                        <div className="col-6 mt-3">
                            <button
                                type="button"
                                className="w-100 btn btn-cancelar btn-block rounded"
                                onClick={() => fecharPesagem(pbRef)}
                            >
                                <Icon className="me-1 mb-1" path={mdiWeight} size={0.8} />
                                Fechar Pesagem
                            </button>
                        </div>
                    </Row>


                    <Row className="mt-3">
                        <Tabela
                            coluna={colunas}
                            dados={dados}
                            itemsPerPage={10}
                            onVisuClick={visualizarTabelaOS}
                            onEditClick={editarUsuario}
                            onDeleteClick={excluirUsuario}
                            id="codigo"
                            permissao_editar={permissao_editar}
                            permissao_visualizar={permissao_visualizar}
                            permissao_excluir={permissao_excluir}
                        />
                    </Row>
                </Card.Body>

            </Card>
        </>
    )
}

export default PesagemUA