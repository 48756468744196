import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../../components/Formularios/Modal/ModalLoading";
import { useState, useEffect } from "react";
import ApiGet from "../../../../../api/endPoints/useGet";
import { toast } from "react-toastify";
import { Card, Col, Form, Row } from "react-bootstrap";
import InputSemBorda from "../../../../../components/Formularios/Inputs/InputsSemBorda";
import SelectInput from "../../../../../shared/SelectInputs";
import { Tab, Box, Switch, SpeedDial, SpeedDialIcon, SpeedDialAction } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button } from "../../../../../components/Formularios/Buttons/Button";
import { mdiCheckCircle, mdiKeyboardReturn } from "@mdi/js";
import ApiPost from "../../../../../api/endPoints/usePost";
import ApiPut from "../../../../../api/endPoints/usePut";
import { getSessionData } from "../../../../../utils/storageUtils";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

interface FormPerfilProps {
  idperfil?: string;
  acao?: string;
}

const FormPerfil = ({ idperfil, acao }: FormPerfilProps) => {
  const token = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const navigate = useNavigate();

  //#region Controla as guias
  const [guia, setGuia] = useState("1");

  const capturaGuia = (event: React.SyntheticEvent, novaGuia: string) => {
    setGuia(novaGuia);
  };
  //#endregion

  const [id_perfil, setIdPerfil] = useState<string>("");
  const [nome_perfil, setNomePerfil] = useState<string>("");
  const [ativo, setAtivo] = useState<boolean>(true);
  const [master, setMaster] = useState<boolean>(false);
  const [NivelPerfil, setNivelPerfil] = useState<any>("")

  //#region REALIZA REQUISIÇÃO PARA EXIBIR OS DADOS DO USUÁRIO
  const [loading, setLoading] = useState<boolean>(false);
  const carregarDados = async (idperfil: any) => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:idperfil",
      parametros: {
        idperfil: idperfil,
      },
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {

        setIdPerfil(retorno[0].id_perfil[0])
        setNomePerfil(retorno[0].nome_perfil)
        setNivelPerfil(retorno[0].nivel)

        if (retorno[0].situacao === 1) {
          setAtivo(true);
        } else {
          setAtivo(false);
        }
        if (retorno[0].master === 1) {
          setMaster(true);
          setAllPermissaoPerfilMaster(!master)
        } else {
          setMaster(false);
        }

        if (retorno[0].editar_codsistema === 1) {
          setEditarCodSistema(true)
        }
        else {
          setEditarCodSistema(false)
        }

        const setFunctions: { [key: string]: (value: boolean) => void } = {
          visualizar_empresa_holding: setVisualizarEmpresaHolding,
          incluir_empresa_holding: setIncluirEmpresaHolding,
          editar_empresa_holding: setEditarEmpresaHolding,
          excluir_empresa_holding: setExcluirEmpresaHolding,
          visualizar_perfil_permissao: setVisualizarPerfilPermissao,
          incluir_perfil_permissao: setIncluirPerfilPermissao,
          editar_perfil_permissao: setEditarPerfilPermissao,
          excluir_perfil_permissao: setExcluirPerfilPermissao,
          visualizar_usuario: setVisualizarUsuario,
          incluir_usuario: setIncluirUsuario,
          editar_usuario: setEditarUsuario,
          excluir_usuario: setExcluirUsuario,
          visualizar_codsistema: setVisualizarCodSistema,
          incluir_codsistema: setIncluirCodSistema,
          editar_codsistema: setEditarCodSistema,
          excluir_codsistema: setExcluirCodSistema,
          visualizarfilial: setVisualizarFilial,
          incluirfilial: setIncluirFilial,
          editarfilial: setEditarFilial,
          excluirfilial: setExcluirFilial,
          visualizarPainel: setVisualizarPainel
        };

        // Iterar sobre as propriedades e definir os estados com base nos valores de retorno[0] -
        //OBS: obg gpt
        for (const key in retorno[0]) {
          if (key in setFunctions) {
            setFunctions[key](retorno[0][key] === 1);
          }
        }

        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao carregar dados. Motivo: ${erro.message}`);
      });
  };
  useEffect(() => {
    if (idperfil !== "novo") {
      carregarDados(idperfil);
    }

  }, [idperfil]);
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA ATUALIZAR OS DADOS DO USUÁRIO
  const atualizarDados = async (
    id_perfil: string,
    NivelPerfil: number,
    nome_perfil: string,
    situacao: number,
    visualizarEmpresaHolding: number,
    incluirEmpresaHolding: number,
    EditarEmpresaHolding: number,
    ExcluirEmpresaHolding: number,

    visualizarPerfilPermissao: number,
    incluirPerfilPermissao: number,
    EditarPerfilPermissao: number,
    ExcluirPerfilPermissao: number,


    visualizarUsuario: number,
    incluirUsuario: number,
    EditarUsuario: number,
    ExcluirUsuario: number,

    visualizarCodSistema: number,
    incluirCodSistema: number,
    EditarCodSistema: number,
    ExcluirCodSistema: number,

    visualizarFilial: number,
    incluirFilial: number,
    EditarFilial: number,
    ExcluirFilial: number,

    visualizarPainel: number
  ) => {
    const dados = {
      nome_perfil,
      NivelPerfil,
      situacao,
      //dados para permissao 
      visualizarEmpresaHolding,
      incluirEmpresaHolding,
      EditarEmpresaHolding,
      ExcluirEmpresaHolding,

      visualizarPerfilPermissao,
      incluirPerfilPermissao,
      EditarPerfilPermissao,
      ExcluirPerfilPermissao,


      visualizarUsuario,
      incluirUsuario,
      EditarUsuario,
      ExcluirUsuario,
      AcessoTotalUsuario,

      visualizarCodSistema,
      incluirCodSistema,
      EditarCodSistema,
      ExcluirCodSistema,

      visualizarFilial,
      incluirFilial,
      EditarFilial,
      ExcluirFilial,

      visualizarPainel
    };

    ApiPut(`/perfil/atualizar/${id_perfil}`, dados, token)
      .then((retorno) => {
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        toast.error(
          `Erro ao atualizar dados. Motivo: ${erro.response.data.message}`
        );
      });
  };
  //#endregion

  //#region REALIZA REQUISIÇÃO PARA GRAVAR OS DADOS DO USUÁRIO
  const gravarDados = (
    nome_perfil: string,
    NivelPerfil: number,
    situacao: number,
    master: number,
    visualizarEmpresaHolding: number,
    incluirEmpresaHolding: number,
    EditarEmpresaHolding: number,
    ExcluirEmpresaHolding: number,

    visualizarPerfilPermissao: number,
    incluirPerfilPermissao: number,
    EditarPerfilPermissao: number,
    ExcluirPerfilPermissao: number,


    visualizarUsuario: number,
    incluirUsuario: number,
    EditarUsuario: number,
    ExcluirUsuario: number,

    visualizarCodSistema: number,
    incluirCodSistema: number,
    EditarCodSistema: number,
    ExcluirCodSistema: number,

    visualizarFilial: number,
    incluirFilial: number,
    EditarFilial: number,
    ExcluirFilial: number,

    visualizarPainel: number
  ) => {
    const dados = {
      nome_perfil,
      NivelPerfil,
      situacao,
      master,

      //dados para permissao 
      visualizarEmpresaHolding,
      incluirEmpresaHolding,
      EditarEmpresaHolding,
      ExcluirEmpresaHolding,

      visualizarPerfilPermissao,
      incluirPerfilPermissao,
      EditarPerfilPermissao,
      ExcluirPerfilPermissao,


      visualizarUsuario,
      incluirUsuario,
      EditarUsuario,
      ExcluirUsuario,
      AcessoTotalUsuario,

      visualizarCodSistema,
      incluirCodSistema,
      EditarCodSistema,
      ExcluirCodSistema,

      visualizarFilial,
      incluirFilial,
      EditarFilial,
      ExcluirFilial,

      visualizarPainel
    };
    ApiPost("/perfil/gravar", dados, token)
      .then((retorno) => {
        console.log(retorno)
        toast.success(`${retorno.message}`);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((erro) => {
        const msg =
          erro.response?.data.message === undefined
            ? erro.message
            : erro.response.data.message;
        toast.error(`Erro ao gravar dados. Motivo: ${msg}`);
      });
  };
  //#endregion

  const gravar = (event: React.FormEvent<HTMLFormElement>) => {
    console.log(NivelPerfil)
    event.preventDefault();
    if (idperfil === "novo") {
      gravarDados(nome_perfil, NivelPerfil ,ativo ? 1 : 0, master ? 1 : 0,
        visualizarEmpresaHolding ? 1 : 0,
        incluirEmpresaHolding ? 1 : 0,
        EditarEmpresaHolding ? 1 : 0,
        ExcluirEmpresaHolding ? 1 : 0,
        visualizarPerfilPermissao ? 1 : 0,
        incluirPerfilPermissao ? 1 : 0,
        EditarPerfilPermissao ? 1 : 0,
        ExcluirPerfilPermissao ? 1 : 0,
        visualizarUsuario ? 1 : 0,
        incluirUsuario ? 1 : 0,
        EditarUsuario ? 1 : 0,
        ExcluirUsuario ? 1 : 0,
        visualizarCodSistema ? 1 : 0,
        incluirCodSistema ? 1 : 0,
        EditarCodSistema ? 1 : 0,
        ExcluirCodSistema ? 1 : 0,
        visualizarFilial ? 1 : 0,
        incluirFilial ? 1 : 0,
        EditarFilial ? 1 : 0,
        ExcluirFilial ? 1 : 0,
        visualizarPainel ? 1 : 0
      );
      toast.info("Inserindo novo registro...");
    } else {
      atualizarDados(id_perfil, NivelPerfil ,nome_perfil, ativo ? 1 : 0,
        visualizarEmpresaHolding ? 1 : 0,
        incluirEmpresaHolding ? 1 : 0,
        EditarEmpresaHolding ? 1 : 0,
        ExcluirEmpresaHolding ? 1 : 0,
        visualizarPerfilPermissao ? 1 : 0,
        incluirPerfilPermissao ? 1 : 0,
        EditarPerfilPermissao ? 1 : 0,
        ExcluirPerfilPermissao ? 1 : 0,
        visualizarUsuario ? 1 : 0,
        incluirUsuario ? 1 : 0,
        EditarUsuario ? 1 : 0,
        ExcluirUsuario ? 1 : 0,
        visualizarCodSistema ? 1 : 0,
        incluirCodSistema ? 1 : 0,
        EditarCodSistema ? 1 : 0,
        ExcluirCodSistema ? 1 : 0,
        visualizarFilial ? 1 : 0,
        incluirFilial ? 1 : 0,
        EditarFilial ? 1 : 0,
        ExcluirFilial ? 1 : 0,
        visualizarPainel ? 1 : 0
      );
      toast.info("Atualizando dados do registro...");
    }
  };


  //controle "aba" ADMINISTRATIVO - inicio

  //informações de permissões
  const [visualizarEmpresaHolding, setVisualizarEmpresaHolding] = useState(false)
  const [incluirEmpresaHolding, setIncluirEmpresaHolding] = useState(false)
  const [EditarEmpresaHolding, setEditarEmpresaHolding] = useState(false)
  const [ExcluirEmpresaHolding, setExcluirEmpresaHolding] = useState(false)
  const [AcessoTotalEmpresaHolding, setAcessoTotalEmpresaHolding] = useState(false)

  const [visualizarPerfilPermissao, setVisualizarPerfilPermissao] = useState(false)
  const [incluirPerfilPermissao, setIncluirPerfilPermissao] = useState(false)
  const [EditarPerfilPermissao, setEditarPerfilPermissao] = useState(false)
  const [ExcluirPerfilPermissao, setExcluirPerfilPermissao] = useState(false)
  const [AcessoTotalPerfilPermissao, setAcessoTotalPerfilPermissao] = useState(false)

  const [visualizarUsuario, setVisualizarUsuario] = useState(false)
  const [incluirUsuario, setIncluirUsuario] = useState(false)
  const [EditarUsuario, setEditarUsuario] = useState(false)
  const [ExcluirUsuario, setExcluirUsuario] = useState(false)
  const [AcessoTotalUsuario, setAcessoTotalUsuario] = useState(false)

  const [visualizarCodSistema, setVisualizarCodSistema] = useState(false)
  const [incluirCodSistema, setIncluirCodSistema] = useState(false)
  const [EditarCodSistema, setEditarCodSistema] = useState(false)
  const [ExcluirCodSistema, setExcluirCodSistema] = useState(false)
  const [AcessoTotalCodSistema, setAcessoTotalCodSistema] = useState(false)

  const [visualizarPainel, setVisualizarPainel] = useState<boolean>(false)


  //controle "aba" ADMINISTRATIVO - fim


  //controle "aba" CADASTRO - inicio
  const [visualizarFilial, setVisualizarFilial] = useState(false)
  const [incluirFilial, setIncluirFilial] = useState(false)
  const [EditarFilial, setEditarFilial] = useState(false)
  const [ExcluirFilial, setExcluirFilial] = useState(false)
  const [AcessoTotalFilial, setAcessoTotalFilial] = useState(false)


  //controle do acesso total perfil MASTER
  function setAllPermissaoPerfilMaster(status_master: any) {
    if (status_master == true) {
      setVisualizarEmpresaHolding(true)
      setIncluirEmpresaHolding(true)
      setEditarEmpresaHolding(true)
      setExcluirEmpresaHolding(true)
      setAcessoTotalEmpresaHolding(true)

      setVisualizarPerfilPermissao(true)
      setIncluirPerfilPermissao(true)
      setEditarPerfilPermissao(true)
      setExcluirPerfilPermissao(true)
      setAcessoTotalPerfilPermissao(true)

      setVisualizarUsuario(true)
      setIncluirUsuario(true)
      setEditarUsuario(true)
      setExcluirUsuario(true)
      setAcessoTotalUsuario(true)

      setVisualizarCodSistema(true)
      setIncluirCodSistema(true)
      setEditarCodSistema(true)
      setExcluirCodSistema(true)
      setAcessoTotalCodSistema(true)

      setVisualizarFilial(true)
      setIncluirFilial(true)
      setEditarFilial(true)
      setExcluirFilial(true)
      setAcessoTotalFilial(true)

    }
    else {
      setVisualizarEmpresaHolding(false)
      setIncluirEmpresaHolding(false)
      setEditarEmpresaHolding(false)
      setExcluirEmpresaHolding(false)
      setAcessoTotalEmpresaHolding(false)

      setVisualizarPerfilPermissao(false)
      setIncluirPerfilPermissao(false)
      setEditarPerfilPermissao(false)
      setExcluirPerfilPermissao(false)
      setAcessoTotalPerfilPermissao(false)

      setVisualizarUsuario(false)
      setIncluirUsuario(false)
      setEditarUsuario(false)
      setExcluirUsuario(false)
      setAcessoTotalUsuario(false)

      setVisualizarCodSistema(false)
      setIncluirCodSistema(false)
      setEditarCodSistema(false)
      setExcluirCodSistema(false)
      setAcessoTotalCodSistema(false)

      setVisualizarFilial(false)
      setIncluirFilial(false)
      setEditarFilial(false)
      setExcluirFilial(false)
      setAcessoTotalFilial(false)

    }

  }

  //manipulando o "acesso total" de cada modulo
  function acessoTotalCheked() {
    if (visualizarEmpresaHolding == true && incluirEmpresaHolding == true && EditarEmpresaHolding == true && ExcluirEmpresaHolding == true) {
      setAcessoTotalEmpresaHolding(true)
    }
    if (visualizarPerfilPermissao == true && incluirPerfilPermissao == true && EditarPerfilPermissao == true && ExcluirPerfilPermissao == true) {
      setAcessoTotalPerfilPermissao(true)
    }
    if (visualizarUsuario == true && incluirUsuario == true && EditarUsuario == true && ExcluirUsuario == true) {
      setAcessoTotalUsuario(true)
    }
    if (visualizarCodSistema == true && incluirCodSistema == true && EditarCodSistema == true && ExcluirCodSistema == true) {
      setAcessoTotalCodSistema(true)
    }
    if (visualizarFilial == true && incluirFilial == true && EditarFilial == true && ExcluirFilial == true) {
      setAcessoTotalFilial(true)
    }
  }

  const actions = [
    {
      icon: (
        <SupervisorAccountIcon
          onClick={() => navigate("/menu/menuAdministrativo")}
        />
      ),
      name: "Menu Administrativo",
    }
  ];


  return (
    <>
      <Card>
        <Form onSubmit={gravar}>
          <Card.Body>
            <Row className="mb-4">
              <SpeedDial
                ariaLabel="SpeedDial basic example"
                direction="left"
                sx={{
                  right: 0,
                  position: "absolute",
                }}
                icon={<SpeedDialIcon />}
                FabProps={{ size: "large" }}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                  />
                ))}
              </SpeedDial>
            </Row>
            <Box sx={{ width: "100%" }}>
              <TabContext value={guia}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={capturaGuia}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#000",
                        color: "#000",
                      },
                    }}
                  >
                    <Tab label="Geral" value="1" />
                    <Tab label="Permissões" value="2" onClick={function () {
                      acessoTotalCheked()
                    }} />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Row>
                    <Col>
                      <h4>Informações sobre campo Nivel Perfil</h4>
                      <tr>0 - Perfil Master</tr>
                      <tr>1 - Adm Filial</tr>
                      <tr>3 - Operacional</tr>
                      <tr>4 - Depositante</tr>
                      <tr><i>*Quanto menor o nivel mais permissões terá.</i></tr>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={2}>
                      <InputSemBorda
                        label="Cód. Perfil"
                        name="codperfil"
                        type="text"
                        placeholder="Cód. Perfil"
                        readonly
                        value={id_perfil || "novo"}
                        onChange={setIdPerfil}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={2}>
                      <div className="form-floating w-100">

                        <input type="text" list="datalistNivelPerfil"
                          className="form-control bordasInferiorInput text-black text-capitalize"
                          placeholder="Nivel Perfil"
                          value={NivelPerfil}
                          aria-label="Nivel Perfil"
                          onChange={function (e: any) {

                            if (e.target.value / 1 != e.target.value) {
                              toast.error("Valor inválido para o campo Nivel Perfil")
                            } else if (e.target.value > 4) {
                              toast.error("Valor inválido para o campo Nivel Perfil")
                            }
                            else {
                              setNivelPerfil(e.target.value)
                            }
                          }}
                        />
                        <label htmlFor="">Nivel Perfil</label>
                      </div>

                      <datalist id="datalistNivelPerfil">
                        {/* Opções do datalist */}
                        <option value="0" />
                        <option value="1" />
                        <option value="2" />
                        <option value="3" />
                        <option value="4" />
                      </datalist>
                    </Col>
                    <Col sm={4}>
                      <InputSemBorda
                        label="Nome Perfil"
                        name="nomeperfil"
                        type="text"
                        placeholder="Nome Perfil"
                        required
                        readonly={acao === "Visualizar" ? true : false}
                        value={nome_perfil}
                        onChange={setNomePerfil}
                        classNameInputsSemBorda="form-control bordasInferiorInput text-black"
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Ativo
                      </label>
                      <Switch
                        checked={ativo}
                        onChange={() => setAtivo(!ativo)}
                        disabled={acao === "Visualizar" ? true : false}
                      />
                    </Col>
                    <Col sm={2}>
                      <br />
                      <label htmlFor="" className="form-label me-2">
                        Perfil Master
                      </label>
                      <Switch
                        checked={master}
                        onChange={function () {
                          setMaster(!master)
                          setAllPermissaoPerfilMaster(!master)
                        }}
                        disabled={
                          acao === "Visualizar" || isSuper === "0"
                            ? true
                            : false
                        }
                        color="error"
                      />
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value="2">
                  {/*INICIO - ADMINISTRATIVO */}
                  <div className="accordion" id="acordeao-exemplo">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-1">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-1"

                          aria-expanded="true"
                          aria-controls="item-1"

                        >

                          Administrativo

                        </h4>

                      </h2>
                      <div

                        id="item-1"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-1"
                        data-bs-parent="#acordeao-exemplo"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            {/*INICIO EMPRESA HOLDING */}
                            <table hidden={true} className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Empresa (Holding)</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuEmpresaHolding"
                                      checked={visualizarEmpresaHolding}
                                      onChange={function (e: any) {
                                        setVisualizarEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirEmpresaHolding"
                                      checked={incluirEmpresaHolding}
                                      onChange={function (e: any) {
                                        setIncluirEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarEmpresaHolding"
                                      checked={EditarEmpresaHolding}
                                      onChange={function (e: any) {
                                        setEditarEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirEmpresaHolding"
                                      checked={ExcluirEmpresaHolding}
                                      onChange={function (e: any) {
                                        setExcluirEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalEmpresaHolding"
                                      checked={AcessoTotalEmpresaHolding}
                                      onChange={function (e: any) {
                                        setAcessoTotalEmpresaHolding(e.target.checked)
                                        setExcluirEmpresaHolding(e.target.checked)
                                        setEditarEmpresaHolding(e.target.checked)
                                        setIncluirEmpresaHolding(e.target.checked)
                                        setVisualizarEmpresaHolding(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/* FIM EMPRESA HOLDING */}
                            {/*INICIO PERFIL / PERMISSÃO */}
                            <table hidden={true} className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Perfil / Permissão</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuPerfilPermissao"
                                      checked={visualizarPerfilPermissao}
                                      onChange={function (e: any) {
                                        setVisualizarPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirPerfilPermissao"
                                      checked={incluirPerfilPermissao}
                                      onChange={function (e: any) {
                                        setIncluirPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarPerfilPermissao"
                                      checked={EditarPerfilPermissao}
                                      onChange={function (e: any) {
                                        setEditarPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirPerfilPermissao"
                                      checked={ExcluirPerfilPermissao}
                                      onChange={function (e: any) {
                                        setExcluirPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalPerfilPermissao"
                                      checked={AcessoTotalPerfilPermissao}
                                      onChange={function (e: any) {
                                        setAcessoTotalPerfilPermissao(e.target.checked)
                                        setExcluirPerfilPermissao(e.target.checked)
                                        setEditarPerfilPermissao(e.target.checked)
                                        setIncluirPerfilPermissao(e.target.checked)
                                        setVisualizarPerfilPermissao(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/*FIM PERFIL / PERMISSÃO */}
                            {/*INICIO USUARIO */}
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Usuario</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuUsuario"
                                      checked={visualizarUsuario}
                                      onChange={function (e: any) {
                                        setVisualizarUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirUsuario"
                                      checked={incluirUsuario}
                                      onChange={function (e: any) {
                                        setIncluirUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarUsuario"
                                      checked={EditarUsuario}
                                      onChange={function (e: any) {
                                        setEditarUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirUsuario"
                                      checked={ExcluirUsuario}
                                      onChange={function (e: any) {
                                        setExcluirUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalUsuario"
                                      checked={AcessoTotalUsuario}
                                      onChange={function (e: any) {
                                        setAcessoTotalUsuario(e.target.checked)
                                        setExcluirUsuario(e.target.checked)
                                        setEditarUsuario(e.target.checked)
                                        setIncluirUsuario(e.target.checked)
                                        setVisualizarUsuario(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>

                                <tr id="tpr-1">
                                  <td className="w-25">Painel de monitoramento</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuUsuario"
                                      checked={visualizarPainel}
                                      onChange={function (e: any) {
                                        setVisualizarPainel(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/*FIM USUARIO */}
                            {/*INICIO CODIGO SISTEMA */}
                            <table hidden={true} className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Código do Sistema</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuCodSistema"
                                      checked={visualizarCodSistema}
                                      onChange={function (e: any) {
                                        setVisualizarCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirCodSistema"
                                      checked={incluirCodSistema}
                                      onChange={function (e: any) {
                                        setIncluirCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarCodSistema"
                                      checked={EditarCodSistema}
                                      onChange={function (e: any) {
                                        setEditarCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirCodSistema"
                                      checked={ExcluirCodSistema}
                                      onChange={function (e: any) {
                                        setExcluirCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalCodSistema"
                                      checked={AcessoTotalCodSistema}
                                      onChange={function (e: any) {
                                        setAcessoTotalCodSistema(e.target.checked)
                                        setExcluirCodSistema(e.target.checked)
                                        setEditarCodSistema(e.target.checked)
                                        setIncluirCodSistema(e.target.checked)
                                        setVisualizarCodSistema(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/*FIM CODIGO SISTEMA */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIM - ADMINISTRATIVO */}
                  <br />
                  {/*INICIO - CADASTRO */}
                  <div className="accordion" id="acordeao-exemplo2">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="titulo-2">
                        <h4

                          className="accordion-button"

                          data-bs-toggle="collapse"

                          data-bs-target="#item-2"

                          aria-expanded="true"
                          aria-controls="item-2"

                        >

                          Cadastro

                        </h4>

                      </h2>
                      <div

                        id="item-2"
                        className="accordion-collapse collapse show"
                        aria-labelledby="titulo-2"
                        data-bs-parent="#acordeao-exemplo2"
                      >
                        <div className="accordion-body">

                          <div className="table-responsive">
                            <table className="table table-centered w-100 dt-responsive nowrap" id="table-permissoes-relatorios">
                              <thead className="table-light">
                                <tr title="Clique para Expandir/Recolher">
                                  <th>Módulo</th>
                                  <th className="text-center">Visualizar</th>
                                  <th className="text-center">Incluir</th>
                                  <th className="text-center">Editar</th>
                                  <th className="text-center">Excluir</th>
                                  <th className="text-center">Acesso Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="tpr-1">
                                  <td className="w-25">Filial</td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_visuFilial"
                                      checked={visualizarFilial}
                                      onChange={function (e: any) {
                                        setVisualizarFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_incluirFilial"
                                      checked={incluirFilial}
                                      onChange={function (e: any) {
                                        setIncluirFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_editarFilial"
                                      checked={EditarFilial}
                                      onChange={function (e: any) {
                                        setEditarFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_ExcluirFilial"
                                      checked={ExcluirFilial}
                                      onChange={function (e: any) {
                                        setExcluirFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input type="checkbox"
                                      id="cbPerm_AcessoTotalFilial"
                                      checked={AcessoTotalFilial}
                                      onChange={function (e: any) {
                                        setAcessoTotalFilial(e.target.checked)
                                        setExcluirFilial(e.target.checked)
                                        setEditarFilial(e.target.checked)
                                        setIncluirFilial(e.target.checked)
                                        setVisualizarFilial(e.target.checked)
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* FIM - CADASTROS*/}
                  <br />

                </TabPanel>
              </TabContext>
            </Box>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex my-2 gap-1 justify-content-between">
              <Button
                invisivel={false}
                type="button"
                className="btn btn-light text-danger bg-white border-0"
                descricaoBotao="Voltar"
                icone={mdiKeyboardReturn}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <Button
                invisivel={false}
                type="submit"
                className="btn btn-light text-info-emphasis bg-white border-0"
                descricaoBotao="Salvar"
                icone={mdiCheckCircle}
              />
            </div>
          </Card.Footer>
        </Form>
      </Card>
      <ModalLoading show={loading} label="Carregado dados do perfil ..." />
    </>
  );
};
export default FormPerfil;
