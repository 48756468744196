import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { BsBoxSeam } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import { FaPlus } from "react-icons/fa";
import { LiaPalletSolid } from "react-icons/lia";
import { setgid } from "process";

//Interface para receber as informações por parametros
interface ModalGtinPadraoProps {
    isOpen: boolean;
    fecharModal: () => void;
    WMS: number;
    token: string;
    idFilial: string;
    CNPJ: string;
    setMostraModalPadrao: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalGtinPadrao: React.FC<ModalGtinPadraoProps> = ({
    isOpen,
    fecharModal,
    WMS,
    token,
    idFilial,
    CNPJ,
    setMostraModalPadrao
}) => {

    const [gtin, setGtin] = useState<string | number>()

    const gtinRef = useRef(null)
    const btnRef = useRef(null)

    const navigate = useNavigate()

    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    function consultaGtin(ref: any) {
        if (
            gtin == 0 ||
            gtin == undefined ||
            gtin == ""
        ) {
            toast.info("Informe o Gtin")
            return
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/conferir/gtin/${gtin}/${CNPJ}/${WMS}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setMostraModalPadrao(true)
            fecharModal()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                ref.current.focus()
                setGtin("")
                toast.error(erro.response.data)
            }
        })
    }

    return (
        <>
            <Modal className="mt-3" show={isOpen} onShow={() => { 
                setGtin("")
            }}>
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center', color: 'black' }}>{"Barra"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={() => {
                            fecharModal()
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">

                    <Row>
                        <div className="col form-floating mt-1">
                            <input
                                type="text"
                                autoFocus
                                value={gtin}
                                ref={gtinRef}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onKeyDown={(event) => handleKeyDown(event, btnRef)}
                                onChange={(event) => setGtin(event.target.value)}
                                onBlur={() => consultaGtin(gtinRef)}
                            />
                            <label className="form-label">Gtin</label>
                        </div>
                    </Row>

                    <Row className="justify-content-center">
                        <div className='col-10 mt-3'>
                            <button
                                type="button"
                                className='mb-2 w-100 text-center btn btn-cancelar btn-block rounded'
                                onClick={() => {
                                    consultaGtin(gtinRef)
                                }}
                                ref={btnRef}
                            >
                                Conferir
                            </button>
                        </div>
                    </Row>

                </Modal.Body>

            </Modal>
        </>
    )
}

export default ModalGtinPadrao