import { Card, Row } from "react-bootstrap"
import Tabela from "../../../../components/Formularios/Table"
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { getSessionData } from "../../../../utils/storageUtils";
import axios from "axios";
import ModalLoading from "../../../../components/Formularios/Modal/ModalLoading";
import { toast } from "react-toastify";
import ModalNovoEquipamento from "./components/ModalNovoEquipamento";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import ClickableTable from "../../../../components/ClickTable/ClickTable";

function Equipamentos() {

    //Variaveis de estado para controle do componente
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(false)
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)
    const [permissao_baixar, setPermissaoBaixar] = useState(true)
    const [mostrarModal, setMostrarModal] = useState(false)
    const [dados, setDados] = useState([])
    const [mostralModalLoading, setMostraModalLoading] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    //const processo = params.processo

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")
    const usuarioLogado = getSessionData("NomeUsuario")

    //Colunas da tabela
    const colunas: ITabela[] = [
        { titulo: "Equipamento", acesso: "EQUIPAMENTO" },
        { titulo: "Qtde Retirada", acesso: "QTD_RETIRA" }
    ];

    //Funções para controle de ações da tabela
    const editarEquipamento = () => { };
    const excluirEquipamento = async (dados: any) => {
        await showConfirmationDialog(
            "Excluir Equipamento",
            `Tem certeza que deseja excluir equipamento\n ${dados.EQUIPAMENTO}?`,
            "question"
        ).then(function (resposta) {
            if (resposta.confirmed) {
                axios.delete(`${process.env.REACT_APP_API_BASE_URL}/excluir/equipamentos/${params.processo}?id=${dados.ID}&idFilial=${idFilial}`, {
                    headers: {
                        Authorization: token
                    }
                }).then(function (resposta) {
                    toast.success(resposta.data)
                    consultaEquipamento()
                }).catch(function (erro) {
                    toast.error("Um erro inesperado aconteceu ao tentar excluir equipamento")
                })
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(
                    `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                );
            }
        })
    };
    const visualizarEquipamento = () => { };
    const BtnBaixarOS = () => { }

    //Função para buscar os equipamentos e preencher na tabela
    function consultaEquipamento() {
        setMostraModalLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/equipamentos/${params.processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data)
            setMostraModalLoading(false)
        }).catch(function (erro) {
            setMostraModalLoading(false)
            if (erro.response.status == 406) {
                const status = erro.response.status
                if (status == 403 || status == 401) {
                    toast.error("Acesso negado, faça seu login novamente")
                    navigate("/login")
                } else {
                    setDados([])
                    console.info(erro.response.data)
                }
            }
        })
    }

    //Funcao para fechar modal 
    function fecharModal() {
        setMostrarModal(false)
    }

    //useEffect para rodar função quando a pagina abre
    useEffect(() => {
        console.log(params.processo)
        consultaEquipamento()
    }, [])

    return (
        <>
            <Card className="mt-2">
                <Card.Body>
                    <Row>
                        <div className="col-2 mt-1">
                            <IoMdArrowBack
                                style={{ color: 'DarkOrange' }}
                                size={28}
                                onClick={() => navigate(-1)}
                            />
                        </div>
                        <div className="col-10  d-flex align-items-center mb-3 ">
                            <button
                                type="button"
                                className='w-100 text-light btn btn-cancelar btn-block rounded'
                                style={{ whiteSpace: 'nowrap' }}
                                onClick={() => setMostrarModal(true)}
                            >
                                <span className='me-1'> <FaPlus size={15} /></span>
                                Novo Equipamento
                            </button>
                        </div>
                    </Row>
                    {/* <Row>
                        <Tabela
                            coluna={colunas}
                            dados={dados}
                            itemsPerPage={10}
                            onVisuClick={visualizarEquipamento}
                            onEditClick={editarEquipamento}
                            onDeleteClick={excluirEquipamento}
                            onBaixarClick={BtnBaixarOS}
                            id="codigo"
                            permissao_editar={permissao_editar}
                            permissao_visualizar={permissao_visualizar}
                            permissao_excluir={permissao_excluir}
                            permissao_baixar={permissao_baixar}
                        />
                    </Row> */}

                    <Row>
                        <ClickableTable 
                        coluna={colunas}
                        data={dados}
                        itemsPerPage={10}
                        onRowClick={function () {}}
                        usaAcoes={true}
                        usaExcluir={true}
                        acaoExcluir={excluirEquipamento}
                        labelpesquisa="Procure pelo equipamento"
                        acessopesquisa="EQUIPAMENTO"
                        />
                    </Row>

                </Card.Body>
            </Card>
            <ModalNovoEquipamento
                isOpen={mostrarModal}
                fecharModal={fecharModal}
                idFilial={idFilial}
                token={token}
                processo={params.processo}
                usuarioLogado={usuarioLogado}
                consultaEquipamento={consultaEquipamento}
            />
            <ModalLoading show={mostralModalLoading} />
        </>
    )
}

export default Equipamentos