import { ITabela } from "./TabelaInterface";
import React, { useEffect, useState } from "react";
import { Button } from "../Buttons/Button";
import { mdiEye, mdiFileEditOutline, mdiTrashCanOutline, mdiDownload } from "@mdi/js";
import Avatar from "@mui/material/Avatar";
import Paginacao from "../Pagination";
import { Badge } from "react-bootstrap";

interface TabelaProps {
  coluna: ITabela[];
  dados: any[];
  itemsPerPage: number;
  usaVisu?: boolean;
  usaEdit?: boolean;
  usaDelete?: boolean;
  usaBaixar?: boolean;
  onVisuClick: (dados: any) => void;
  onEditClick: (dados: any) => void;
  onDeleteClick: (dados: any) => void;
  onBaixarClick?: (dados: any) => void;
  id?: string;
  descEditar?: string;
  descExcluir?: string;
  descVisualizar?: string;
  descBaixar?: string,
  iconeEditar?: string;
  iconeExcluir?: string;
  iconeVisualizar?: string,
  iconeBaixar?: string,
  permissao_visualizar: boolean,
  permissao_editar: boolean,
  permissao_excluir: boolean,
  permissao_baixar?: boolean,
  viewAcoesConfSaida?: boolean,
}
const Tabela: React.FC<TabelaProps> = ({
  coluna,
  dados,
  itemsPerPage,
  usaVisu = true,
  usaEdit = true,
  usaDelete = true,
  usaBaixar = true,
  onVisuClick,
  onDeleteClick,
  onEditClick,
  onBaixarClick = function () { },
  id,
  descEditar,
  descExcluir,
  descVisualizar,
  descBaixar,
  iconeEditar,
  iconeExcluir,
  iconeVisualizar,
  iconeBaixar,
  permissao_visualizar,
  permissao_editar,
  permissao_excluir,
  permissao_baixar = true,
  viewAcoesConfSaida = true
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(dados.length / itemsPerPage);
  const itens = dados.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const [viewAcoesConf, setViewAcoesConf] = useState<any>(true)
  const [viewAcoesPadrao, setViewAcoesPadrao] = useState<any>(false)

  useEffect(() => {
    if (viewAcoesConfSaida == false) {
      setViewAcoesConf(false)
      setViewAcoesPadrao(true)
    }
  }, [viewAcoesConfSaida])

  return (
    <div className="table-responsive">
      <table className="table table-centered w-100 dt-responsive nowrap table-striped table-hover">
        <thead className="table-cabecalho">
          <tr>
            <th hidden={viewAcoesConf}>Ações</th>
            {coluna.map((item, index) => (
              <th key={index}>{item.titulo}</th>
            ))}
            <th hidden={viewAcoesPadrao}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {itens.map((item, index) => (
            <tr key={index}>
              <td hidden={viewAcoesConf}>
                {usaVisu && (
                  <Button
                    invisivel={permissao_visualizar}
                    onclick={() => onVisuClick(item)}
                    icone={iconeVisualizar || mdiEye}
                    type="button"
                    className="botaoTransparente"
                    title={descVisualizar || "Visualizar"}
                  />
                )}
                {usaEdit && (
                  <Button
                    invisivel={permissao_editar}
                    onclick={() => onEditClick(item)}
                    icone={iconeEditar || mdiFileEditOutline}
                    type="button"
                    className="botaoTransparente"
                    title={descEditar || "Editar"}
                  />
                )}
                {usaDelete && (
                  <Button
                    invisivel={permissao_excluir}
                    onclick={() => onDeleteClick(item)}
                    icone={iconeExcluir || mdiTrashCanOutline}
                    type="button"
                    className="botaoTransparente"
                    title={descExcluir || "Excluir"}
                  />
                )}
                {usaBaixar && (
                  <Button
                    invisivel={permissao_baixar}
                    onclick={() => onBaixarClick(item)}
                    icone={iconeBaixar || mdiDownload}
                    type="button"
                    className="botaoTransparente"
                    title={descBaixar || "Baixar"}
                  />
                )}
              </td>

              {coluna.map((coluna, index: any) => (
                <td key={index}>
                  {coluna.acesso === "avatar" ? (
                    item[coluna.acesso] !== "" ? (
                      <Avatar
                        src={`data:image/png;base64,${item[coluna.acesso]}`}
                        alt="Avatar"
                      />
                    ) : (
                      <Avatar src={""} alt="Avatar" />
                    )
                  ) : coluna.acesso === "situacao" ? (
                    item[coluna.acesso] === 1 ? (
                      <Badge bg="primary">Ativo</Badge>
                    ) : (
                      <Badge bg="danger">Inativo</Badge>
                    )
                  ) : coluna.acesso === "status" ? (
                    item[coluna.acesso] === "AGENDADO" ? (
                      <Badge bg="secondary">AGENDADO</Badge>
                    ) : item[coluna.acesso] === "AGUARDANDO OPERACAO" ? (
                      <Badge bg="primary">AGUARDANDO OPERACAO</Badge>
                    ) : item[coluna.acesso] === "EM OPERACAO" ? (
                      <Badge bg="warning">EM OPERAÇÃO</Badge>
                    ) : item[coluna.acesso] === "CONCLUIDO" ? (
                      <Badge bg="success">CONCLUIDO</Badge>
                    ) : item[coluna.acesso] === "CANCELADO" ? (
                      <Badge bg="danger">CANCELADO</Badge>
                    ) : (
                      item[coluna.acesso]
                    )
                  ) : (
                    item[coluna.acesso]
                  )}
                </td>
              ))}
              <td hidden={viewAcoesPadrao}>
                {usaVisu && (
                  <Button
                    invisivel={permissao_visualizar}
                    onclick={() => onVisuClick(item)}
                    icone={iconeVisualizar || mdiEye}
                    type="button"
                    className="botaoTransparente"
                    title={descVisualizar || "Visualizar"}
                  />
                )}
                {usaEdit && (
                  <Button
                    invisivel={permissao_editar}
                    onclick={() => onEditClick(item)}
                    icone={iconeEditar || mdiFileEditOutline}
                    type="button"
                    className="botaoTransparente"
                    title={descEditar || "Editar"}
                  />
                )}
                {usaDelete && (
                  <Button
                    invisivel={permissao_excluir}
                    onclick={() => onDeleteClick(item)}
                    icone={iconeExcluir || mdiTrashCanOutline}
                    type="button"
                    className="botaoTransparente"
                    title={descExcluir || "Excluir"}
                  />
                )}
                {usaBaixar && (
                  <Button
                    invisivel={permissao_baixar}
                    onclick={() => onBaixarClick(item)}
                    icone={iconeBaixar || mdiDownload}
                    type="button"
                    className="botaoTransparente"
                    title={descBaixar || "Baixar"}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginacao
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        dados={dados}
      />
    </div>
  );
};
export default Tabela;
