import { Card, Row } from "react-bootstrap"
import Tabela from "../../../../components/Formularios/Table"
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import { useEffect, useRef, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { getSessionData } from "../../../../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";
import ClickableTable from "../../../../components/ClickTable/ClickTable";
import { speak } from  "../../../../utils/notifySons"

function LerVolume() {
    /* comentado const sem uso
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)
    const [permissao_baixar, setPermissaoBaixar] = useState(true)
    */
    const [rg, setRg] = useState<any>()
    const [dados, setDados] = useState([])
    const [rgTotal, setRgTotal] = useState<number>()
    const [rgLido, setRglido] = useState<number>()

    const RGRef = useRef(null)

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")
    const usuarioLogado = getSessionData("NomeUsuario")

    const navigate = useNavigate()
    const params = useParams()
    const OS = params.OS
    const processo = params.PROCESSO

    //Funções para controle de ações da tabela
    /* comentado const sem uso
    const editarUsuario = () => { };
    const excluirUsuario = () => { };
    const visualizarTabelaOS = () => { };
    const btnBaixarOS = () => { }
    
    */
    function gravarUA(ref: any) {
        if (rg == 0 || rg == null || rg == undefined) {
            toast.info('O RG não pode ser nulo')
            return;
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/gravar/UA/?idFilial=${idFilial}`, {
            usuarioLogado,
            OS,
            rg
        },
            {
                headers: {
                    Authorization: token
                }
            }).then(function (resposta) {
                toast.success(resposta.data)
                speak(resposta.data)
                carregaGrid()
                setRg("")
                ref.current?.focus()
            }).catch(function (erro) {
                const status = erro.response.status
                if (status == 403 || status == 401) {
                    toast.error("Acesso negado, faça seu login novamente")
                    navigate("/login")
                } else if (status == 406) {
                    toast.error(erro.response.data)
                    speak(erro.response.data)
                }
            })
    }

    function carregaGrid() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/grid/${OS}/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            let totalPedido = 0
            let totalConferido = 0
            for (let i = 0; i < resposta.data.data.length; i++) {
                totalPedido += resposta.data.data[i].linhas_pedido
                totalConferido += resposta.data.data[i].linhas_conferido
            }
            //console.log(totalPedido)
            setRgTotal(totalPedido)
            setRglido(resposta.data.qtdeLido)
            // console.log(totalPedido)
            // console.log(totalConferido)
            if (totalPedido == totalConferido) {
                // navigate(-1)
            }
            setDados(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                //console.log(erro)
            }
        })
    }


    //Criação das colunas da tabela
    const colunas: ITabela[] = [
        { titulo: "Pedido", acesso: "pedido" },
        { titulo: "Qtde. Pedido", acesso: "linhas_pedido" },
        { titulo: "Qtde. Conferido", acesso: "linhas_conferido" },
    ];

    useEffect(() => {
        carregaGrid()
    }, [])

    return (
        <>
            <Card className="mt-2">
                <Card.Body>
                    <Row>
                        <div className="col-2">
                            <IoMdArrowBack
                                style={{ color: 'DarkOrange' }}
                                size={28}
                                onClick={() => navigate(-1)}
                            />
                        </div>

                        <div className="col-5 form-floating">
                            <input
                                type="number"
                                value={rgTotal}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">RG'S TOTAL</label>
                        </div>

                        <div className="col-5 form-floating">
                            <input
                                type="number"
                                value={rgLido}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">RG'S LIDOS</label>
                        </div>
                    </Row>
                    <div className="mt-2 d-flex align-items-center">
                        <label style={{ minWidth: '80px', maxWidth: '80px' }}>RG Volume</label>
                        <input
                            type="number"
                            id="U.A."
                            className="w-75"
                            autoFocus
                            value={rg}
                            style={{ height: '30px' }}
                            ref={RGRef}
                            onChange={(event) => {
                                setRg(event.target.value)
                            }}
                            onBlur={() => {
                                gravarUA(RGRef)
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    gravarUA(RGRef)
                                }
                            }}
                        />
                        {/* <button type="button" style={{ visibility: 'hidden' }}><IoCamera /></button> */}
                    </div>


                    {/* <Row className="mt-3">
                        <Tabela
                            coluna={colunas}
                            dados={dados}
                            itemsPerPage={10}
                            onVisuClick={visualizarTabelaOS}
                            onEditClick={editarUsuario}
                            onDeleteClick={excluirUsuario}
                            onBaixarClick={btnBaixarOS}
                            id="codigo"
                            permissao_editar={permissao_editar}
                            permissao_visualizar={permissao_visualizar}
                            permissao_excluir={permissao_excluir}
                            permissao_baixar={permissao_baixar}
                        />
                    </Row> */}

                    <Row className="mt-3">
                        <ClickableTable
                            coluna={colunas}
                            data={dados}
                            itemsPerPage={10}
                            onRowClick={function () { }}
                            usaAcoes={false}
                        />
                    </Row>

                </Card.Body>
            </Card>
        </>
    )
}

export default LerVolume
