
//função responsável por reproduzir o texto passado como audio
const speak = (text: string): void => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.pitch = 2; // Ajusta o tom (0 a 2)
      utterance.rate = 2;  // Ajusta a velocidade (0.1 a 10)
      utterance.volume = 1; // Ajusta o volume (0 a 1)
      window.speechSynthesis.speak(utterance);   
    }
  };

export {speak};