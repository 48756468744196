import { useParams } from "react-router-dom";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import Formfilial from "./components/formulario";

export function FilialEditar() {
  const idfilial = useParams();
  
  return (
    <>
    {
      console.log(idfilial)
    }
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Editar Filial" />
        <Formfilial idfilial={idfilial.id} acao="Editar" />
      </div>
    </>
  );
}
