import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { BsBoxSeam } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";

//Interface para receber as informações por parametros
interface ModalPickingPadraoProps {
    isOpen: boolean;
    fecharModal: () => void;
    pedido: any;
    codigo: any;
    descricao: any;
    qtdePedido: any;
    minValidade: any;
    // qtdeSeparado: any;
    CNPJ: any;
    dataCarga: any;
    lote: any;
    WMSPedido: any;
    usuarioLogado: any;
    pedidoCliente: any;
    idPedido: any;
    unidade: any;
    idFilial: any;
    token: any;
}

const ModalPickingPadrao: React.FC<ModalPickingPadraoProps> = ({
    isOpen,
    fecharModal,
    pedido,
    codigo,
    descricao,
    qtdePedido,
    minValidade,
    // qtdeSeparado,
    CNPJ,
    dataCarga,
    lote,
    WMSPedido,
    usuarioLogado,
    pedidoCliente,
    idPedido,
    unidade,
    idFilial,
    token
}) => {
    const [validade, setValidade] = useState<any>()
    const [UA, setUA] = useState<any>()
    const [usar, setUsar] = useState<number>()
    const [saldoUA, setSaldoUA] = useState<any>()
    const [validadeUA, setValidadeUA] = useState<any>()
    const [codigoUA, setCodigoUA] = useState<any>()
    const [wmsUA, setWmsUA] = useState<any>()
    const [viewValidade, setViewValidade] = useState(true)
    const [viewLote, setViewLote] = useState(true)
    const [qtdeSeparado, setQtdeSeparado] = useState(0)

    const UARef = useRef(null)
    const usarRef = useRef(null)
    const btnSepararRef = useRef(null)

    const navigate = useNavigate()

    function handleModalOpen() {
        if (!lote) {
            setViewLote(true)
            setViewValidade(false)
            if (!minValidade) {
                setValidade(dataCarga)
            } else {
                setValidade(minValidade)
            }
        } else {
            setViewLote(false)
            setViewValidade(true)
        }

        setUA("")
        setSaldoUA("")
        setUsar(0)
        setValidadeUA("")
        setCodigoUA("")
        setWmsUA("")
    }

    function consultaUA(ref: any) {
        if (!UA) {
            toast.info("Insira um número válido U.A")
            setSaldoUA("")
            setValidadeUA("")
            setCodigoUA("")
            setWmsUA("")
            setUsar(0)
            return;
        }

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/consultar/UA/padrao?idFilial=${idFilial}`, {
            UA,
            CNPJ,
            WMSPedido,
            codigo,
            lote,
            validade
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setSaldoUA(resposta.data.saldo)
            setValidadeUA(resposta.data.validade)
            setCodigoUA(resposta.data.codigo)
            setWmsUA(resposta.data.WMS)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                toast.info(erro.response.data)
                setUA("")
                setSaldoUA("")
                setValidadeUA("")
                setCodigoUA("")
                setWmsUA("")
                ref.current.focus()
            }
        })
    }

    async function excluirVolumes() {
        await showConfirmationDialog(
            "Excluir Volumes",
            `Tem certeza que deseja excluir volumes\n?`,
            "question"
        ).then(function (resposta) {
            if (resposta.confirmed) {

                //Fazendo um split para desmonstar a data e remontá-la no formato yyyy/mm/dd
                let [day, month, year] = dataCarga.split('/');
                let formattedDate = `${year}-${month}-${day}`;

                axios.delete(`${process.env.REACT_APP_API_BASE_URL}/excluir/volumes/${formattedDate}/${CNPJ}/${WMSPedido}?pedido=${pedido}&idFilial=${idFilial}`, {
                    headers: {
                        Authorization: token
                    }
                }).then(function (resposta) {
                    toast.success(resposta.data.message)
                    consultaQtdeSeparado()
                    setUA("")
                    setUsar(0)
                    setSaldoUA("")
                    setValidadeUA("")
                    setCodigoUA("")
                    setWmsUA("")
                    // consultaEquipamento()
                }).catch(function (erro) {
                    toast.error("Um erro inesperado aconteceu ao tentar excluir volumes")
                })
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(
                    `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                );
            }
        })
    }

    function separarPadrao(ref: any) {
        if (!UA || !usar) {
            toast.info("Preencha os campos para prosseguir")
            ref.current.focus()
            return;
        }

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/separar/padrao?idFilial=${idFilial}`, {
            WMSPedido,
            wmsUA,
            codigo,
            UA,
            validadeUA,
            validade,
            pedido,
            dataCarga,
            CNPJ,
            usuarioLogado,
            pedidoCliente,
            qtdePedido,
            usar,
            idPedido
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            consultaQtdeSeparado()
            toast.success(resposta.data.message)
            if (resposta.data.telaParaAbrir == "seleciona codigo") {
                fecharModal()
            } else if (resposta.data.telaParaAbrir == "seleciona depositante") {
                navigate("/menu/picking")
            } else {
                handleModalOpen()
                ref.current.focus()
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                toast.info(erro.response.data)
                ref.current.focus()
            }
        })
    }

    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    function consultaQtdeSeparado() {
        //Fazendo um split para desmonstar a data e remontá-la no formato yyyy/mm/dd
        let [day, month, year] = dataCarga.split('/');
        let formattedDate = `${year}-${month}-${day}`;

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/padrao/${pedido}/${CNPJ}/${WMSPedido}/${formattedDate}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setQtdeSeparado(resposta.data.qtdSeparado)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(
                    `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
                );
            }
        })
    }

    return (
        <>
            <Modal className="mt-3" show={isOpen} onShow={() => {
                handleModalOpen()
                consultaQtdeSeparado()
            }}>
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center' }}>{"Picking - Padrão"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={() => {
                            fecharModal()
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <Row className="mt-2">
                        <div className="col-7 form-floating mt-1">
                            <input
                                type="number"
                                value={UA}
                                className="form-control bordasInferiorInput text-black"
                                autoFocus
                                ref={UARef}
                                onBlur={() => {
                                    consultaUA(UARef)
                                }}
                                onChange={(event) => {
                                    setUA(event.target.value)
                                }}
                                onKeyDown={async (event) => {
                                    if (event.key === 'Enter') {
                                        await consultaUA(UARef)
                                    }
                                    handleKeyDown(event, usarRef)
                                }}
                            />
                            <label className="form-label">U.A.</label>
                        </div>

                        <div className="col-5 form-floating">
                            <input
                                type="number"
                                value={usar}
                                className="form-control bordasInferiorInput text-black"
                                style={{ textAlign: 'right' }}
                                ref={usarRef}
                                onChange={(event) => {
                                    setUsar(parseInt(event.target.value))
                                }}
                                onKeyDown={(event) => handleKeyDown(event, btnSepararRef)}
                            />
                            <label className="form-label">USAR</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating">
                            <input
                                // type="text"
                                value={saldoUA}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">SALDO U.A.</label>
                        </div>

                        <div className="col-5 form-floating">
                            <input
                                // type="text"
                                value={validadeUA}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">VALIDADE</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating">
                            <input
                                // type="text"
                                value={codigoUA}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">CÓDIGO</label>
                        </div>

                        <div className="col-5 form-floating">
                            <input
                                // type="text"
                                value={wmsUA}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">WMS</label>
                        </div>
                    </Row>

                    <Row className="mt-3 d-flex mb-2 justify-content-between">
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 me-2 btn btn-danger rounded fs-5"
                                onClick={excluirVolumes}
                            >
                                <span className='me-1'>Excluir Volumes</span><MdDeleteOutline size={20} />
                            </button>
                        </div>
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 btn btn-novo rounded fs-5"
                                onClick={() => {
                                    separarPadrao(UARef)
                                }}
                                ref={btnSepararRef}
                            >
                                <span className='me-1'>Separar</span><BsBoxSeam size={20} />
                            </button>
                        </div>
                    </Row>

                    <Row className="mt-2 border border-primary"></Row>

                    <Row>
                        <div className="col form-floating mt-1">
                            <input
                                // type="text"
                                value={pedido}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">PEDIDO</label>
                        </div>

                    </Row>

                    <Row>
                        <div className="col-7 form-floating mt-1">
                            <input
                                // type="text"
                                value={descricao}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">DESCRIÇÃO</label>
                        </div>

                        <div className="col-5 form-floating mt-1">
                            <input
                                // type="text"
                                value={codigo}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">CÓDIGO</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating mt-1" hidden={viewLote}>
                            <input
                                // type="text"
                                value={lote}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">LOTE</label>
                        </div>

                        <div className="col-7 form-floating mt-1" hidden={viewValidade}>
                            <input
                                // type="text"
                                value={validade}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">MIN. VALIDADE</label>
                        </div>

                        <div className="col-5 form-floating mt-1">
                            <input
                                // type="text"
                                value={qtdePedido}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">QTDE. PEDIDO</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating mt-1">
                            <input
                                // type="text"
                                value={qtdeSeparado}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">QTDE. SEPARADO</label>
                        </div>

                        <div className="col-5 form-floating mt-1">
                            <input
                                type="text"
                                value={unidade}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">UN</label>
                        </div>
                    </Row>
                </Modal.Body>

                <Modal.Footer className="bg-white">

                    {/* <Row className="d-flex mb-2 justify-content-between w-100">
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 me-2 btn btn-danger rounded fs-5"
                                onClick={excluirVolumes}
                            >
                                <span className='me-1'>Excluir Volumes</span><MdDeleteOutline size={20} />
                            </button>
                        </div>
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 btn btn-novo rounded fs-5"
                                onClick={() => {
                                    separarPadrao(UARef)
                                }}
                                ref={btnSepararRef}
                            >
                                <span className='me-1'>Separar</span><BsBoxSeam size={20} />
                            </button>
                        </div>
                    </Row> */}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalPickingPadrao