import { Card, Row } from "react-bootstrap"
import { BsCardChecklist } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import ModalCheckList from "./components/ModalCheckList";
import { getSessionData } from "../../../../utils/storageUtils";
import axios from "axios";
import { toast } from "react-toastify";

function CheckList() {
    //Criando variáveis de estado
    const [mostraModal, setMostraModal] = useState(false)
    const [nomeImg1, setNomeBtnImg1] = useState<any>()
    const [nomeImg2, setNomeBtnImg2] = useState<any>()
    const [nomeImg3, setNomeBtnImg3] = useState<any>()
    const [nomeImg4, setNomeBtnImg4] = useState<any>()
    const [nomeImg5, setNomeBtnImg5] = useState<any>()
    const [nomeImg6, setNomeBtnImg6] = useState<any>()
    const [img1, setImg1] = useState(false);
    const [img2, setImg2] = useState(false);
    const [img3, setImg3] = useState(false);
    const [img4, setImg4] = useState(false);
    const [img5, setImg5] = useState(false);
    const [img6, setImg6] = useState(false);
    const [nomeFile, setNomeFile] = useState<any>()

    //Pega o tipo de conferencia em questão
    const params = useParams()
    const tipoConferencia = params.tipoConferencia

    const usuarioLogado = getSessionData("NomeUsuario")
    const idFilial = getSessionData("DadosIdfilial")
    const token = getSessionData("MultfilialWebToken")
    const sessionData = tipoConferencia == "Saida" ? getSessionData("Processo") : tipoConferencia == "Entrada" ? getSessionData("ProcessoEntrada") : "";
    const sessionProcesso = sessionData ? sessionData.replace(/"/g, '') : '';

    //Setando uso do navigate
    const navigate = useNavigate()

    function fecharModal() {
        setMostraModal(false)
    }

    //Function para setar nome dos botões referentes ao cadastro
    function consultaNomeBtnImg() {
        //Requisição para receber consulta do back-end
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/nome/btn/${sessionProcesso}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            //Setando as variáveis referente a reposta do back-end
            const anexo1 = resposta.data[0].out_anexo_1_texto
            const anexo2 = resposta.data[0].out_anexo_2_texto
            const anexo3 = resposta.data[0].out_anexo_3_texto
            const anexo4 = resposta.data[0].out_anexo_4_texto
            const anexo5 = resposta.data[0].out_anexo_5_texto
            const anexo6 = resposta.data[0].out_anexo_6_texto

            //Verificando variáveis com resposta para setar nomes
            anexo1 ? setNomeBtnImg1(anexo1) : setNomeBtnImg1("Imagem 1")
            anexo2 ? setNomeBtnImg2(anexo2) : setNomeBtnImg2("Imagem 2")
            anexo3 ? setNomeBtnImg3(anexo3) : setNomeBtnImg3("Imagem 3")
            anexo4 ? setNomeBtnImg4(anexo4) : setNomeBtnImg4("Imagem 4")
            anexo5 ? setNomeBtnImg5(anexo5) : setNomeBtnImg5("Imagem 5")
            anexo6 ? setNomeBtnImg6(anexo6) : setNomeBtnImg6("Imagem 6")

        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                console.info(erro.response.data)
            }
        })
    }

    //Function para cadastrar imagem 
    function cadastraImg(file: any, nomeFile: any) {
        //Transformando o arquivo em base64 para enviá-lo ao back-end
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            //Apagando parte do nome do arquivo para facilitar a leitura posteriormente
            const result = reader.result!.toString().split("data:image/png;base64,")[1]

            //Requisição enviando dados ao back-end
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/cadastra/imagens/${sessionProcesso}?idFilial=${idFilial}`, {
                nomeFile: nomeFile,
                arquivo: result,
                usuarioLogado
            }, {
                headers: {
                    Authorization: token
                }
            }).then(function (resposta) {
                toast.success(resposta.data)
            }).catch(function (erro) {
                const status = erro.response.status
                if (status == 403 || status == 401) {
                    toast.error("Acesso negado, faça seu login novamente")
                    navigate("/login")
                } else {
                    toast.error("Não foi possível cadastrar imagem")
                    console.log(erro)
                }
            })
        }
    }
 
    //Function consultando quais imagens estão cadastradas para setar a cor do botão
    function consultaImagensCadastradas() {
        //Realizando requisição ao back-end
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/imagens/${sessionProcesso}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            //Obtendo a resposta e criando objeto para setar a cor referente ao arquivo que está cadastrado
            const result = resposta.data;
            const anexos = {
                "Anexo_1": setImg1,
                "Anexo_2": setImg2,
                "Anexo_3": setImg3,
                "Anexo_4": setImg4,
                "Anexo_5": setImg5,
                "Anexo_6": setImg6
            };
            //Utilização do for..of para iterar sobre o array result;
            //Verificando a imagem que está cadastrada para setar cor do botão em questão para verde 'success'
            for (const item of result) {
                const nome = item.nome.toString();
                for (const [anexo, setImg] of Object.entries(anexos)) {
                    if (nome.includes(anexo)) {
                        setImg(true);
                    }
                }
            }
        }).catch(function (erro) {
            console.info(erro.response.data)
        });
    }
    
    //Rodando a função assim que a tela for carregada
    useEffect(() => {
        // console.log(tipoConferencia)
        // console.log(sessionProcesso)
        consultaImagensCadastradas()
        consultaNomeBtnImg()
    }, [])

    return (
        <>
            <Card className="mt-2">
                <Card.Body>
                    <Row>
                        <div className="col-2 mt-1">
                            <IoMdArrowBack
                                style={{ color: 'DarkOrange' }}
                                size={28}
                                onClick={() => navigate(-1)}
                            />
                        </div>
                        <div className="col-10  d-flex align-items-center mb-3 ">
                            <button
                                type="button"
                                className='w-100 text-light btn btn-cancelar btn-block rounded border border-dark'
                                style={{ whiteSpace: 'nowrap' }}
                                onClick={() => setMostraModal(true)}
                            >
                                <i className="bi bi-card-checklist me-2"></i>
                                Preencher
                            </button>
                        </div>
                        <div>
                            <div>
                                <label htmlFor="fileInput" className={`mt-2 btn btn-${img1 ? 'success' : 'cancelar'} border border-dark btn-lg btn-block rounded w-100`}>
                                    {nomeImg1}
                                </label>
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    onChange={(event) => {
                                        setImg1(true)
                                        const file = event.target.files![0]
                                        const fileType = file.type.split("/")[1];
                                        cadastraImg(file, "Anexo_1_Proc_" + sessionProcesso + "." + fileType)
                                    }}
                                />
                            </div>
                            <div>
                                <label htmlFor="fileInput2" className={`mt-2 btn btn-${img2 ? 'success' : 'cancelar'} border border-dark btn-lg btn-block rounded w-100`}>
                                    {nomeImg2}
                                </label>
                                <input
                                    type="file"
                                    id="fileInput2"
                                    style={{ display: 'none' }}
                                    onChange={(event) => {
                                        setImg2(true)
                                        const file = event.target.files![0]
                                        const fileType = file.type.split("/")[1];
                                        cadastraImg(file, "Anexo_2_Proc_" + sessionProcesso + "." + fileType)
                                    }}
                                />
                            </div>
                            <div>
                                <label htmlFor="fileInput3" className={`mt-2 btn btn-${img3 ? 'success' : 'cancelar'} border border-dark btn-lg btn-block rounded w-100`}>
                                    {nomeImg3}
                                </label>
                                <input
                                    type="file"
                                    id="fileInput3"
                                    style={{ display: 'none' }}
                                    onChange={(event) => {
                                        setImg3(true)
                                        const file = event.target.files![0]
                                        const fileType = file.type.split("/")[1];
                                        cadastraImg(file, "Anexo_3_Proc_" + sessionProcesso + "." + fileType)
                                    }}
                                />
                            </div>
                            <div>
                                <label htmlFor="fileInput4" className={`mt-2 btn btn-${img4 ? 'success' : 'cancelar'} border border-dark btn-lg btn-block rounded w-100`}>
                                    {nomeImg4}
                                </label>
                                <input
                                    type="file"
                                    id="fileInput4"
                                    style={{ display: 'none' }}
                                    onChange={(event) => {
                                        setImg4(true)
                                        const file = event.target.files![0]
                                        const fileType = file.type.split("/")[1];
                                        cadastraImg(file, "Anexo_4_Proc_" + sessionProcesso + "." + fileType)
                                    }}
                                />
                            </div>
                            <div>
                                <label htmlFor="fileInput5" className={`mt-2 btn btn-${img5 ? 'success' : 'cancelar'} border border-dark btn-lg btn-block rounded w-100`}>
                                    {nomeImg5}
                                </label>
                                <input
                                    type="file"
                                    id="fileInput5"
                                    style={{ display: 'none' }}
                                    onChange={(event) => {
                                        setImg5(true)
                                        const file = event.target.files![0]
                                        const fileType = file.type.split("/")[1];
                                        cadastraImg(file, "Anexo_5_Proc_" + sessionProcesso + "." + fileType)
                                    }}
                                />
                            </div>
                            <div>
                                <label htmlFor="fileInput6" className={`mt-2 btn btn-${img6 ? 'success' : 'cancelar'} border border-dark btn-lg btn-block rounded w-100`}>
                                    {nomeImg6}
                                </label>
                                <input
                                    type="file"
                                    id="fileInput6"
                                    style={{ display: 'none' }}
                                    onChange={(event) => {
                                        setImg6(true)
                                        const file = event.target.files![0]
                                        const fileType = file.type.split("/")[1];
                                        cadastraImg(file, "Anexo_6_Proc_" + sessionProcesso + "." + fileType)
                                    }}
                                />
                            </div>
                        </div>
                    </Row>
                </Card.Body>
            </Card>

            <ModalCheckList
                isOpen={mostraModal}
                fecharModal={fecharModal}
                idFilial={idFilial}
                token={token}
                processo={sessionProcesso}
            />
        </>
    )
}

export default CheckList