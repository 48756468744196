
import { Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import ClickableTable from '../ClickTable/ClickTable';
import { ITabela } from '../Formularios/Table/TabelaInterface';

interface ModalInfoCompletaProps {
    isOpen: boolean;
    fecharModal: () => void;
    titulo: string;
    dados: any;
    colunas: ITabela[]
    labelFiltroTabela: string
    acessoFiltroTabela: string
}

const ModalInfoCompleta: React.FC<ModalInfoCompletaProps> = ({
    isOpen,
    fecharModal,
    titulo,
    dados,
    colunas,
    labelFiltroTabela,
    acessoFiltroTabela
}) => {

    return (
        <Modal show={isOpen} size='lg' centered>
            <Modal.Header className="bg-white d-flex justify-content-between align-items-center" style={{ justifyContent: 'center' }}>
                <Modal.Title style={{ flex: '1', textAlign: 'center' }}>{titulo}</Modal.Title>
                <i
                    className="bi bi-x-circle"
                    style={{ fontSize: '2rem', cursor: 'pointer' }}
                    onClick={fecharModal}
                ></i>
            </Modal.Header>

            <Modal.Body className="bg-white">
                <Row className="mt-3">
                    <ClickableTable
                        data={dados}
                        itemsPerPage={10}
                        coluna={colunas}
                        onRowClick={function () { }}
                        labelpesquisa={labelFiltroTabela}
                        acessopesquisa={acessoFiltroTabela}
                    />
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalInfoCompleta