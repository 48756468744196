import { Form, Link, useNavigate } from "react-router-dom";
import { getSessionData } from "../../utils/storageUtils";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

function MenuAdministrativo() {
    const [viewBtn, setViewBtn] = useState(true)
    const [viewPainel, setViewPainel] = useState<boolean>(true)

    const navigate = useNavigate()

    const idPerfil = getSessionData("id_perfil") ?? ""
    const token = getSessionData("MultfilialWebToken") ?? ""
    const idFilial = getSessionData("DadosIdfilial") ?? ""

    function verificaPerfil() {
        if (idPerfil == '1') {
            setViewBtn(false)
        } else {
            setViewBtn(true)
        }
    }

    useEffect(() => {
        verificaPerfil()
        verificaPermissaoPainel()
    })

    function verificaPermissaoPainel() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/permissaoPainel/${idPerfil}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            if (resposta.data.data === 1) {
                setViewPainel(false)
            } else {
                setViewPainel(true)
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } 
        })
    }

    return (
        <>
            <Card className="mt-4">

                <div className="row mt-2 text-center mt-md-4">
                    <div className="mt-1 mb-3 text-center">
                        <button
                            type="button"
                            className="btn text-light bg-primary border border-dark btn-lg btn-block w-75"
                            onClick={() => navigate("/cadastro/filiais/filial")}
                            hidden={viewBtn}
                        >
                            Filiais
                        </button>
                        <button
                            type="button"
                            className="btn mt-3 text-light bg-primary border border-dark btn-lg btn-block w-75"
                            onClick={() => navigate("/administrativo/permisao")}
                            hidden={viewBtn}
                        >
                            Perfil/Permissão
                        </button>
                        <button
                            type="button"
                            className="mt-3 btn text-light bg-primary border border-dark btn-lg btn-block w-75"
                            onClick={() => navigate("/administrativo/usuario")}
                        >
                            Usuário
                        </button>
                        <button
                            type="button"
                            className="mt-3 btn text-light bg-primary border border-dark btn-lg btn-block w-75"
                            onClick={() => navigate("/grid/filial")}
                            hidden={viewBtn}
                        >
                            Usuarios/Filial
                        </button>
                        <button
                            type="button"
                            className="mt-3 btn text-light bg-primary border border-dark btn-lg btn-block w-75"
                            onClick={() => navigate("/administrativo/codistema")}
                            hidden={viewBtn}
                        >
                            Código do Sistema
                        </button>
                        <button
                            type="button"
                            hidden={viewPainel}
                            className="mt-3 btn text-light bg-primary border border-dark btn-lg btn-block w-75"
                            onClick={() => navigate("/menu/monitoramento")}
                        // hidden={viewBtn}
                        >
                            Painel de Monitoramento
                        </button>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default MenuAdministrativo