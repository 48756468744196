import Modal from 'react-bootstrap/Modal';

interface TiposModalCarregando {

    mostrar: boolean;
    mensagem: string;
}

/*

 icone: string;
    msgAcao1: string;
    msgAcao2: string;
    Acao1: string;
    Acao2: string;
*/
function ModalCarregando({
    mostrar,
    mensagem
}: TiposModalCarregando) {


    return (
        <Modal show={mostrar} centered>
            <Modal.Body className='text-center'>
                <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                </div>
                <div className='w-100'></div>
                <span>{mensagem}</span>
            </Modal.Body>
        </Modal>
    )
}

export default ModalCarregando