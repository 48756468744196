import axios from "axios";
import { ChangeEvent, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LiaPalletSolid } from "react-icons/lia";
import { FaPlus } from "react-icons/fa";

//Interface para receber as informações por parametros
interface ModalVarRomManualProps {
    isOpen: boolean;
    fecharModal: () => void;
    processo: number;
    WMS: number;
    codigo: string;
    tipoPeso: string;
    descricao: string;
    token: string;
    idFilial: string;
    usuarioLogado: string;
    usaValidade: boolean;
    usaLote: boolean;
}

const ModalVarRomManual: React.FC<ModalVarRomManualProps> = ({
    isOpen,
    fecharModal,
    processo,
    WMS,
    codigo,
    tipoPeso,
    descricao,
    token,
    idFilial,
    usuarioLogado,
    usaValidade,
    usaLote
}) => {

    const [validade, setValidade] = useState<string>()
    const [UA, setUA] = useState<number | string>()
    const [qtdeVol, setQtdeVol] = useState<number | string>()
    const [lote, setLote] = useState<number | string>()
    const [plEmb, setPlEmb] = useState<number | string>()
    const [viewBtnGravarEmb, setViewBtnGravarEmb] = useState<boolean>(true)
    const [viewBtnGravarUA, setViewBtnGravarUA] = useState<boolean>(false)
    const [plInserido, setPlInserido] = useState<number | string>()

    const UARef = useRef(null)
    const qtdeVolRef = useRef(null)
    const validadeRef = useRef(null)
    const loteRef = useRef(null)
    const btnGravarRef = useRef(null)
    const plEmbRef = useRef<HTMLInputElement>(null)

    const navigate = useNavigate()

    function consultaUA(ref: any, qtdeVolRef: any) {
        if (!UA) {
            setQtdeVol("")
            setValidade("")
            setLote("")
            setPlEmb("")
            return;
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/UA/confEntrada/${UA}/${processo}/${WMS}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setViewBtnGravarUA(true)
            setViewBtnGravarEmb(false)
            buscaPLInserido()
            if (resposta.data == 'UA existe como vazia') {
                setQtdeVol("")
                setValidade("")
                setLote("")
                setPlEmb("")
                qtdeVolRef.current.focus()
            } else {
                setQtdeVol(resposta.data.qtdeVol)
                const date = new Date(resposta.data.validade)
                let validadeUA = date.toISOString().substring(0, 10).replace(/-/g, '/')
                let [year, month, day] = validadeUA.split('/')
                setValidade(`${day}/${month}/${year}`)
                setLote(resposta.data.lote)
                plEmbRef.current?.focus()
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                setUA("")
                setQtdeVol("")
                setValidade("")
                setLote("")
                setPlEmb("")
                ref.current.focus()
                toast.error(erro.response.data)
            }
        })
    }

    function gravarUA(ref: any, pesoRef: any) {
        if (!UA || !qtdeVol || !plEmb) {
            toast.info("Preencha todos os campos para gravar a UA")
            return
        }

        if (usaValidade && (!validade || validade.length !== 10)) {
            return toast.info('Preencha validade corretamente para prosseguir');
        }

        if (usaLote && (!lote)) {
            return toast.info("Preencha o lote para prosseguir")
        }

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/gravar/UA/varRomManual?idFilial=${idFilial}`, {
            UA,
            qtdeVol,
            validade,
            lote,
            processo,
            codigo,
            plEmb,
            usuarioLogado,
            WMS,
            usaValidade,
            usaLote
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data.message)
            setQtdeVol(resposta.data.qtde)

            if (usaValidade) {
                setValidade(resposta.data.validade)
            }

            pesoRef.current.focus()
            setPlEmb("")
            setViewBtnGravarEmb(false)
            setViewBtnGravarUA(true)
            buscaPLInserido()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                setUA("")
                setQtdeVol("")
                setValidade("")
                setLote("")
                setPlEmb("")
                ref.current.focus()
                toast.error(erro.response.data)
            }
        })
    }

    function criarNovaUA(ref: any, qtdeVolRef: any) {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/criar/nova/UA?idFilial=${idFilial}`, {
            processo,
            usuarioLogado
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setUA(resposta.data.UA)
            setQtdeVol("")
            setValidade("")
            setLote("")
            setPlEmb("")
            qtdeVolRef.current.focus()
            setViewBtnGravarUA(true)
            setViewBtnGravarEmb(false)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
            else if (status == 406) {
                toast.error(erro.response.data)
                ref.current.focus()
            }
        })
    }

    function buscaPLInserido() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/plInserido/${UA}/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setPlInserido(resposta.data.pl)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
        })
    }

    const handleValidadeChange = (e: ChangeEvent<HTMLInputElement>) => {
        // Remove todos os caracteres não numéricos
        let input = e.target.value.replace(/\D/g, '');
        if (input.length > 8) {
            // Limita a entrada a 8 caracteres
            input = input.slice(0, 8);
        }

        if (input.length <= 2) {
            input = input;
        } else if (input.length <= 4) {
            input = `${input.slice(0, 2)}/${input.slice(2)}`;
        } else {
            input = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4)}`;
        }

        setValidade(input);
    };

    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };


    return (
        <>
            <Modal className="mt-3" show={isOpen} onShow={() => {
                setUA("")
                setQtdeVol("")
                setValidade("")
                setLote("")
                setPlEmb("")
                setPlInserido("")
                setViewBtnGravarEmb(true)
                setViewBtnGravarUA(false)
            }}>
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center', color: 'black' }}>{"Variado Romaneio Manual"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={() => {
                            fecharModal()
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <Row className="justify-content-between">
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                autoFocus
                                value={UA}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                ref={UARef}
                                onKeyDown={(event) => handleKeyDown(event, qtdeVolRef)}
                                onChange={(event) => setUA(event.target.value)}
                                onBlur={() => consultaUA(UARef, qtdeVolRef)}
                                onFocus={() => {
                                    setViewBtnGravarEmb(true)
                                    setViewBtnGravarUA(false)
                                }}
                            />
                            <label className="form-label">NÚMERO U.A</label>
                        </div>

                        <div className="col-6 mt-2">
                            <button
                                type="button"
                                className="w-100 btn btn-cancelar rounded fs-5"
                                onClick={() => criarNovaUA(UARef, qtdeVolRef)}
                            >
                                <span className='me-2'>Criar nova U.A.</span> <LiaPalletSolid className="mb-1" size={20} />

                            </button>
                        </div>
                    </Row>
                    <Row>
                        <div className="col form-floating mt-1">
                            <input
                                type="number"
                                value={qtdeVol}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                ref={qtdeVolRef}
                                onKeyDown={(event) => handleKeyDown(event, validadeRef)}
                                onChange={(event) => setQtdeVol(event.target.value)}
                            />
                            <label className="form-label">QUANTIDADE DE VOLUMES</label>
                        </div>
                    </Row>
                    <Row>
                        <div className="col form-floating mt-1">
                            <input
                                type="text"
                                value={validade}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={handleValidadeChange}
                                maxLength={10}
                                ref={validadeRef}
                                onKeyDown={(event) => handleKeyDown(event, loteRef)}
                                disabled={!usaValidade}
                            />
                            <label className="form-label">VALIDADE</label>
                        </div>
                    </Row>
                    <Row>
                        <div className="col form-floating mt-1">
                            <input
                                type="number"
                                value={lote}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(event) => setLote(event.target.value)}
                                ref={loteRef}
                                onKeyDown={(event) => handleKeyDown(event, plEmbRef)}
                                disabled={!usaLote}
                            />
                            <label className="form-label">LOTE</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col form-floating mt-1">
                            <input
                                type="number"
                                value={plEmb}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(event) => setPlEmb(event.target.value)}
                                ref={plEmbRef}
                                onKeyDown={(event) => handleKeyDown(event, btnGravarRef)}
                                onBlur={() => gravarUA(UARef, plEmbRef)}
                            />
                            <label className="form-label">PESO LÍQUIDO EMBALAGEM</label>
                        </div>
                    </Row>

                    <div className="mt-2" hidden={viewBtnGravarUA}>
                        <button
                            type="button"
                            className="w-100 btn btn-novo rounded fs-5"
                            ref={btnGravarRef}
                            onClick={() => gravarUA(UARef, plEmbRef)}
                        >
                            <span className='me-2'>Gravar U.A</span> <FaPlus className="mb-1" size={18} />
                        </button>
                    </div>

                    <div className="mt-2" hidden={viewBtnGravarEmb}>
                        <button
                            type="button"
                            className="w-100 btn btn-success rounded fs-5"
                            ref={btnGravarRef}
                            onClick={() => gravarUA(UARef, plEmbRef)}
                        >
                            <span className='me-2'>Gravar Embalagem</span> <FaPlus className="mb-1" size={18} />
                        </button>
                    </div>
                    <Row className="mt-2 mb-2 border border-primary"></Row>

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={processo}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">PROCESSO</label>
                        </div>

                        <div className="col-6 form-floating mt-1">
                            <input
                                type="number"
                                value={plInserido}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">PESO LIQ</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-6 form-floating mt-1">
                            <input
                                type="text"
                                value={codigo}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">CÓDIGO</label>
                        </div>

                        <div className="col-6 form-floating mt-1">
                            <input
                                type="text"
                                value={tipoPeso}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">TIPO DE PESO</label>
                        </div>
                    </Row>

                    <Row className="mb-2">
                        <div className="col form-floating mt-1">
                            <input
                                type="text"
                                value={descricao}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                disabled
                            />
                            <label className="form-label">DESCRIÇÃO</label>
                        </div>
                    </Row>

                </Modal.Body>

                <Modal.Footer className="bg-white">


                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalVarRomManual