import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap"
import ClickableTable from "../../../components/ClickTable/ClickTable";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import axios from "axios";
import { getSessionData } from "../../../utils/storageUtils";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import ModalInfoCompleta from "../../../components/ModalInfoCompleta/ModalInfoCompleta";
import { IoMdArrowBack } from "react-icons/io";
import ModalCarregando from "../../../components/ModalCarregando/ModalCarregando";

function ProdutividadeGtin() {
    const [dataInicio, setDataInicio] = useState<string>('');
    const [dataFim, setDataFim] = useState<string>('');
    const [dados, setDados] = useState([])
    const [dadosCompletos, setDadosCompletos] = useState([])
    const [mostraModalDados, setMostraModalDados] = useState<boolean>(false)
    const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)

    const navigate = useNavigate()
    const params = useParams()
    const tipoUsuario = params.tipoUsuario

    useEffect(() => {
        const dataAtual = new Date();
        dataAtual.setDate(dataAtual.getDate() - 1);
        const dataFormatada = dataAtual.toISOString().split('T')[0];

        setDataInicio(dataFormatada);
        setDataFim(dataFormatada);

    }, []);

    const colunas: ITabela[] = [
        { titulo: "Qtde. Lido", acesso: "qtde" },
        { titulo: "Usuário", acesso: "usuario" },
        { titulo: "Dt. Leitura", acesso: "dt" }
    ];

    const colunasCompletas: ITabela[] = [
        { titulo: "Gtin", acesso: "gtin" },
        { titulo: "O.S", acesso: "os" },
        { titulo: "Processo", acesso: "processo" },
        { titulo: "Dt. Leitura", acesso: "cadastro" },
        { titulo: "Usuário", acesso: "usuario" }
    ]

    const token = getSessionData("MultfilialWebToken") ?? "";
    const idFilial = getSessionData("DadosIdfilial") ?? "";
    const usuarioLogado = getSessionData("NomeUsuario") ?? "";

    async function carregaDados(dataInicio: string, dataFim: string) {
        setMostraModalCarregando(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/produtividadeGtin/${usuarioLogado}/${dataInicio}/${dataFim}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data.data)
        }).catch(function (erro) {
            setDados([])
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status === 500) {
                toast.error(erro.response.data.message)
            }
        }).finally(() => {
            setMostraModalCarregando(false)
        })
    }

    async function carregaDadosUsuarios(dataInicio: string, dataFim: string) {
        setMostraModalCarregando(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/produtividade/usuarios/${dataInicio}/${dataFim}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data.data)
        }).catch(function (erro) {
            setDados([])
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status === 500) {
                toast.error(erro.response.data.message)
            }
        }).finally(() => {
            setMostraModalCarregando(false)
        })
    }

    async function carregaDadosUsuarioUnico(dataLeitura: string, usuario: string) {
        const [dia, mes, ano] = dataLeitura.split('/')
        const data = `${ano}-${mes}-${dia}`

        setMostraModalCarregando(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/produtividadeUnica/${usuario}/${data}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDadosCompletos(resposta.data.data)
        }).catch(function (erro) {
            setDados([])
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status === 500) {
                toast.error(erro.response.data.message)
            }
        }).finally(() => {
            setMostraModalCarregando(false)
        })
    }

    useEffect(() => {
        if (dataInicio && dataFim) {
            if (tipoUsuario === 'comum') {
                carregaDados(dataInicio, dataFim);
            } else {
                carregaDadosUsuarios(dataInicio, dataFim)
            }
        }
    }, [dataInicio, dataFim])

    return (
        <>
            <Card className="mt-2">
                <Card.Body>
                    <Row>
                        <div className="col-2 mb-1">
                            <IoMdArrowBack
                                style={{ color: 'DarkOrange', cursor: 'pointer' }}
                                size={28}
                                onClick={() => navigate(-1)}
                            />
                        </div>
                    </Row>

                    <Row>
                        <Col xs={6}>
                            <div className="form-group">
                                <label htmlFor="inputDataInicio">Selecione o início</label>
                                <input
                                    type="date"
                                    id="inputDataInicio"
                                    className="form-control"
                                    value={dataInicio}
                                    onChange={(e) => {
                                        if (e.target.value > dataFim) {
                                            toast.info("Não é possível escolher uma data maior que a data final")
                                        } else {
                                            setDataInicio(e.target.value)
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="form-group">
                                <label htmlFor="inputDataFim">Selecione o fim</label>
                                <input
                                    type="date"
                                    id="inputDataFim"
                                    className="form-control"
                                    value={dataFim}
                                    onChange={(e) => {
                                        if (e.target.value < dataInicio) {
                                            toast.info("Não é possível escolher uma data menor que a data inicial")
                                        } else {
                                            setDataFim(e.target.value)
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-3" hidden={tipoUsuario === 'admin' ? false : true}>
                        <ClickableTable
                            data={dados}
                            coluna={colunas}
                            itemsPerPage={30}
                            onRowClick={function (dados) {
                                setMostraModalDados(true)

                                carregaDadosUsuarioUnico(dados.dt, dados.usuario)
                            }}
                            labelpesquisa="Pesquise pelo usuário"
                            acessopesquisa="usuario"
                        />
                    </Row>

                    <Row className="mt-3" hidden={tipoUsuario === 'comum' ? false : true}>
                        <ClickableTable
                            data={dados}
                            coluna={colunas}
                            itemsPerPage={10}
                            onRowClick={function (dados) {
                                setMostraModalDados(true)

                                carregaDadosUsuarioUnico(dados.dt, dados.usuario)
                            }}
                        />
                    </Row>
                </Card.Body>
            </Card>

            <ModalInfoCompleta
                isOpen={mostraModalDados}
                fecharModal={() => setMostraModalDados(false)}
                titulo="Produtividade Leitura de Gtin"
                dados={dadosCompletos}
                colunas={colunasCompletas}
                labelFiltroTabela="Pesquise pela O.S"
                acessoFiltroTabela="os"
            />

            <ModalCarregando
                mostrar={mostraModalCarregando}
                mensagem="Carregando..."
            />
        </>
    )
}

export default ProdutividadeGtin