import { Modal, Row } from "react-bootstrap"

interface ModalConfirmProps {
    isOpen: boolean;
    fecharModal: () => void;
    onConfirm: (OSBaixa: any) => void;
    OSBaixa: any;
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
    isOpen,
    fecharModal,
    onConfirm,
    OSBaixa
}) => {

    return (
        <>
            <Modal className='mt-5'
                show={isOpen}>
                <Modal.Header className="bg-white " style={{ justifyContent: 'center' }}>
                    <Modal.Title className="text-center">{"Existe uma U.A. bloqueada, deseja prosseguir com a baixa da OS?"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <Row className="d-flex justify-content-between">
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 me-2 btn btn-danger rounded"
                                onClick={fecharModal}
                            >
                                Cancelar
                            </button>
                        </div>
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 btn btn-cancelar rounded"
                                onClick={() => {
                                    onConfirm(OSBaixa)
                                    fecharModal()
                                }}
                            >
                                Confirmar
                            </button>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalConfirm