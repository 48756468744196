import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Row, Alert, ModalFooter } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdDeleteOutline } from 'react-icons/md';
import { BsBoxSeam } from 'react-icons/bs';
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { showConfirmationDialog } from '../../../../components/Formularios/Modal/ModalExcluir';
import { getSessionData } from '../../../../utils/storageUtils';
interface ModalPickingVarBalProps {
    isOpen: boolean;
    fecharModal: () => void;
    pedido: any;
    codigo: string;
    descricao: string;
    qtdePedido: string;
    minValidade: string;
    qtdeSeparado: string;
    dataCarga: string;
    CNPJ: string;
    WMS: number;
    lote: string;
    idPedido: any;
    pedidoCliente: any;
    idFilial: any;
    token: any;
}

const ModalPickingVarBal: React.FC<ModalPickingVarBalProps> = ({
    isOpen,
    fecharModal,
    pedido,
    codigo,
    descricao,
    qtdePedido,
    minValidade,
    // qtdeSeparado,
    dataCarga,
    CNPJ,
    WMS,
    lote,
    idPedido,
    pedidoCliente,
    idFilial,
    token
}) => {
    const [validadeMinima, setValidadeMinima] = useState<any>('');
    const [UA, setUA] = useState<any>('');
    const [saldoUA, setSaldoUA] = useState<any>('');
    const [qtdeASeparar, setQtdeASeparar] = useState<any>('');
    const [pesoLiquido, setPesoLiquido] = useState<any>('');
    const [qtdeSeparado, setQtdeSeparado] = useState<any>()
    const [viewValidade, setViewValidade] = useState(true);
    const [viewLote, setViewLote] = useState(true);
    const [mensagem, setMensagem] = useState<any>(null);
    const [messageConfirm, setMessageConfirm] = useState<any>(null)
    const [pbServer, setPbServer] = useState<any>()
    const [plServer, setPlServer] = useState<any>()

    const UARef = useRef(null);
    const btnConfirmRef = useRef<HTMLButtonElement>(null);
    const qtdeASepararRef = useRef(null);
    const pesoLiqRef = useRef(null);
    const btnSepararRef = useRef(null);
    const btnInserirRef = useRef(null);

    const navigate = useNavigate();

    const usuarioLogado = getSessionData("NomeUsuario")

    function handleModalOpen() {
        if (!lote) {
            setViewLote(true);
            setViewValidade(false);
            if (!minValidade) {
                setValidadeMinima(dataCarga);
            } else {
                setValidadeMinima(minValidade);
            }
        } else {
            setViewLote(false);
            setViewValidade(true);
        }

        setUA('');
        setSaldoUA('');
        setQtdeASeparar('');
        setPesoLiquido('');
    }

    function consultaUA(ref: any, event: any) {
        if (!UA) {
            toast.info('Insira um número válido para U.A');
            return;
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/tipoBalanca/${UA}/${codigo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            // console.log(resposta)
            setMensagem(null)
            setSaldoUA(resposta.data.disponivel);
            handleKeyDown(event, qtdeASepararRef)
        }).catch(function (erro) {
            // console.log(erro)
            const status = erro.response.status;
            setSaldoUA("")
            if (status === 403 || status === 401) {
                toast.error('Acesso negado, faça seu login novamente');
                navigate('/login');
            } else if (status === 406) {
                setMensagem(null)
                ref.current?.focus()
                toast.error(erro.response.data)
            } else if (status == 423) {
                ref.current?.focus()
                setMensagem({
                    title: erro.response.data.title,
                    body: erro.response.data.body
                })
            }
        });
    }

    async function excluirLancamentos() {
        await showConfirmationDialog(
            "Excluir Lançamentos",
            `Tem certeza que deseja excluir lançamentos?`,
            "question"
        ).then(function (resposta) {
            if (resposta.confirmed) {
                axios.delete(`${process.env.REACT_APP_API_BASE_URL}/excluir/lancamentos/${CNPJ}/${codigo}?pedido=${pedido}&idFilial=${idFilial}`, {
                    headers: {
                        Authorization: token
                    }
                }).then(function (resposta) {
                    toast.success(resposta.data)
                    consultaQtdeSeparado()
                    setUA("")
                    setSaldoUA("")
                    setQtdeASeparar("")
                    setPesoLiquido("")
                }).catch(function (erro) {
                    toast.error("Um erro inesperado aconteceu ao tentar excluir lançamentos")
                })
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(
                    `Erro ao excluir lançamento. Motivo: ${erro.response.data.message}`
                );
            }
        })
    }

    function processaSeparacao() {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/processa/separacao?idFilial=${idFilial}`, {
            pedido,
            dataCarga,
            CNPJ,
            WMS,
            qtdePedido,
            codigo
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data)
            consultaQtdeSeparado()
            fecharModal()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                toast.error(erro.response.data)
            }
        })
    }

    function separarValBal(ref: any, PB: any, PL: any) {
        if (!UA || !qtdeASeparar || !pesoLiquido) {
            toast.info("Preencha as informações para prosseguir")
            ref.current.focus()
            return;
        }

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/separar/balanca?idFilial=${idFilial}`, {
            UA,
            qtdeASeparar,
            pesoLiquido,
            PB,
            PL,
            WMS,
            lote,
            validadeMinima,
            CNPJ,
            dataCarga,
            pedido,
            qtdePedido,
            idPedido,
            pedidoCliente,
            usuarioLogado,
            codigo
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data)
            consultaQtdeSeparado()
            setUA("")
            setSaldoUA(0)
            setQtdeASeparar("")
            setPesoLiquido("")
            ref.current.focus()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                toast.error(erro.response.data)
                ref.current.focus()
            }
        })
    }

    function verificaPesoPreSeparar(btnRef: any) {
        if (!UA || !qtdeASeparar || !pesoLiquido) {
            toast.info("Preencha todos os campos para prosseguir")
            return
        }

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/verifica/peso?idFilial=${idFilial}`, {
            UA,
            qtdeASeparar,
            pesoLiquido
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setPbServer(resposta.data.PB)
            setPlServer(resposta.data.PL)
            separarValBal(UARef, resposta.data.PB, resposta.data.PL)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 423) {
                setPbServer(erro.response.data.PB)
                setPlServer(erro.response.data.PL)
                setMessageConfirm(erro.response.data)
                btnRef.current?.focus()
            } else if (status == 406) {
                toast.error(erro.response.data)
            }
        })
    }

    function consultaQtdeSeparado() {
        //Fazendo um split para desmonstar a data e remontá-la no formato yyyy/mm/dd
        let [day, month, year] = dataCarga.split('/');
        let formattedDate = `${year}-${month}-${day}`;

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/qtdeSeparado/variadoBal/${pedido}/${CNPJ}/${WMS}/${formattedDate}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setQtdeSeparado(resposta.data.qtdSeparado)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                toast.error(erro.response.data)
            }
        })
    }

    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    const handleCloseAlertUA = () => {
        setMensagem(null)
    };

    const handleCloseAlertConfirm = () => {
        setMessageConfirm(null)
    }

    useEffect(() => {
        if (messageConfirm) {
            btnConfirmRef.current?.focus();
        }
    }, [messageConfirm])

    return (
        <>
            <Modal show={isOpen} className="mt-3" onShow={() => {
                consultaQtdeSeparado()
                handleModalOpen()
            }}>
                <Modal.Header className="bg-white">
                    <Modal.Title style={{ flex: '1', textAlign: 'center' }}>{"Picking - Variado Balança"}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={fecharModal}
                    ></i>
                </Modal.Header>

                <Modal.Body className="bg-white">
                    {mensagem && (
                        <Alert variant="danger" onClose={handleCloseAlertUA} dismissible>
                            <Alert.Heading>{mensagem.title}</Alert.Heading>
                            <ul>
                                {mensagem.body.map((line: any, index: any) => (
                                    <li key={index}>{line}</li>
                                ))}
                            </ul>
                        </Alert>
                    )}

                    {messageConfirm && (
                        <Alert variant="danger" onClose={handleCloseAlertConfirm} dismissible>
                            <Alert.Heading>{messageConfirm.title}</Alert.Heading>
                            <ul>
                                {messageConfirm.body.map((line: any, index: any) => (
                                    <li key={index}>{line}</li>
                                ))}
                            </ul>
                            <div className="d-flex justify-content-end">
                                <Button
                                    onClick={() => {
                                        separarValBal(UARef, pbServer, plServer)
                                        handleCloseAlertConfirm();
                                    }}
                                    ref={btnConfirmRef}
                                    variant="outline-success"
                                    className="me-2"
                                >
                                    Confirmar
                                </Button>
                                <Button onClick={handleCloseAlertConfirm} variant="outline-danger">
                                    Cancelar
                                </Button>
                            </div>
                        </Alert>
                    )}


                    <Row className="mt-2">
                        <div className="col form-floating">
                            <input
                                type="number"
                                value={UA}
                                ref={UARef}
                                className="form-control bordasInferiorInput text-black"
                                autoFocus
                                onChange={(event) => setUA(event.target.value)}
                                onBlur={(event) => consultaUA(UARef, event)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        consultaUA(UARef, event)
                                    }
                                }}
                            />
                            <label className="form-label">U.A.</label>
                        </div>

                        <div className="col-5 form-floating">
                            <input
                                value={saldoUA}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">SALDO U.A.</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating">
                            <input
                                value={qtdeASeparar}
                                ref={qtdeASepararRef}
                                className="form-control bordasInferiorInput text-black"
                                onChange={(event) => setQtdeASeparar(event.target.value)}
                                onKeyDown={(event) => handleKeyDown(event, pesoLiqRef)}
                            />
                            <label className="form-label">QTDE. A SEPARAR</label>
                        </div>

                        <div className="col-5 form-floating">
                            <input
                                value={pesoLiquido}
                                ref={pesoLiqRef}
                                className="form-control bordasInferiorInput text-black"
                                style={{ textAlign: 'right' }}
                                onChange={(event) => {
                                    const valor = event.target.value.replace(',', '.');
                                    setPesoLiquido(valor);
                                }}
                                onKeyDown={(event) => handleKeyDown(event, btnInserirRef)}
                            />
                            <label className="form-label">PESO LÍQUIDO</label>
                        </div>
                    </Row>


                    <div className='mt-3 '>
                        <Row className="d-flex mb-2 justify-content-between">
                            <div className="col-6">
                                <button
                                    type="button"
                                    className="w-100 me-2 btn btn-danger rounded fs-5"
                                    onClick={excluirLancamentos}
                                >
                                    <span className='me-1'>Excluir</span><MdDeleteOutline size={20} />
                                </button>
                            </div>

                            <div className="col-6">
                                <button
                                    type="button"
                                    className="w-100 btn btn-novo rounded fs-5"
                                    ref={btnInserirRef}
                                    onClick={() => verificaPesoPreSeparar(btnConfirmRef)}
                                >
                                    <span className='me-1'>Inserir</span><BsBoxSeam size={18} />
                                </button>
                            </div>
                        </Row>

                        <Row>
                            <div className="">
                                <button
                                    type="button"
                                    className="me-2 mb-2 w-100 btn btn-cancelar rounded fs-5"
                                    onClick={processaSeparacao}
                                >
                                    <span className='me-1'>Processar Separação</span><IoCheckmarkDoneSharp size={20} />
                                </button>
                            </div>
                        </Row>
                    </div>


                    <Row className="mt-2 border border-primary"></Row>

                    <Row className='mt-2'>
                        <div className="col-7 form-floating mt-1">
                            <input
                                value={pedido}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">PEDIDO</label>
                        </div>

                        <div className="col-5 form-floating mt-1">
                            <input
                                value={codigo}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">CÓDIGO</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating mt-1">
                            <input
                                value={descricao}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">DESCRIÇÃO</label>
                        </div>

                        <div className="col-5 form-floating mt-1">
                            <input
                                value={qtdePedido}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">QTDE. PEDIDO</label>
                        </div>
                    </Row>

                    <Row>
                        <div className="col-7 form-floating mt-1" hidden={viewValidade}>
                            <input
                                value={validadeMinima}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">MIN. VALIDADE</label>
                        </div>

                        <div className="col-7 form-floating" hidden={viewLote}>
                            <input
                                type="text"
                                // value={descricao}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                            />
                            <label className="form-label">LOTE ACEITO</label>
                        </div>

                        <div className="col form-floating mt-1">
                            <input
                                // type="text"
                                value={qtdeSeparado}
                                className="form-control bordasInferiorInput text-black"
                                disabled
                                style={{ textAlign: 'right' }}
                            />
                            <label className="form-label">QTDE. SEPARADO</label>
                        </div>
                    </Row>
                </Modal.Body>

                <ModalFooter className="bg-white">
                    {/* <Row className="d-flex mb-2 justify-content-between w-100">
                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 me-2 btn btn-danger rounded fs-5"
                                onClick={excluirLancamentos}
                            >
                                <span className='me-1'>Excluir</span><MdDeleteOutline size={20} />
                            </button>
                        </div>

                        <div className="col-6">
                            <button
                                type="button"
                                className="w-100 btn btn-novo rounded fs-5"
                                ref={btnInserirRef}
                                // onClick={() => verificaPesoPreSeparar(btnConfirmRef)}
                            >
                                <span className='me-1'>Inserir</span><BsBoxSeam size={18} />
                            </button>
                        </div>
                    </Row>

                    <Row className='w-100'>
                        <div className="">
                            <button
                                type="button"
                                className="me-2 mb-2 w-100 btn btn-cancelar rounded fs-5"
                            // onClick={processaSeparacao}
                            >
                                <span className='me-1'>Processar Separação</span><IoCheckmarkDoneSharp size={20} />
                            </button>
                        </div>
                    </Row> */}
                </ModalFooter>
            </Modal>

        </>
    )
}

export default ModalPickingVarBal