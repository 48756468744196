import { useEffect, useState } from "react"
import ModalSelecionaProcesso from "../components/ModalSelecionaProcesso"
import { toast } from "react-toastify";
import axios from "axios";
import { getSessionData, setSessionData } from "../../../utils/storageUtils";
import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import { Card } from "react-bootstrap";
import ModalBotoes from "../components/ModalBotoes";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import ClickableTable from "../../../components/ClickTable/ClickTable";
import ModalPadrao from "./components/ModalPadrao";
import ModalVarOP from "./components/ModalVarOP";
import ModalVarRomManual from "./components/ModalVarRomManual";
import ModalVarBal from "./components/ModalVarBal";
import ModalGtinPadrao from "./components/ModalGtinPadrao";

function PagPrincipalEntrada() {

    const [MostraModalSelecionaProcesso, setMostraModalSelecionaProcesso] = useState(false)
    const [mostralModalLoading, setMostraModalLoading] = useState(false)
    const [btnIniciar, setBtnIniciar] = useState(false)
    const [btnConcluir, setBtnConcluir] = useState(true)
    const [modalidade, setModalidade] = useState<any>()
    const [viewTableArmDev, setViewTableArmDev] = useState<boolean>(false)
    const [viewTableCrossRe, setViewTableCrossRe] = useState<boolean>(true)
    // const [processoInserido, setProcessoInserido] = useState<any>()
    const [mostraModalBotoes, setMostraModalBotoes] = useState<boolean>(false)
    const [dados, setDados] = useState([])
    const [mostraModalPadrao, setMostraModalPadrao] = useState<boolean>(false)
    const [mostraModalVarOP, setMostraModalVarOP] = useState<boolean>(false)
    const [mostraModalVarRomManual, setMostraModalVarRomManual] = useState<boolean>(false)
    const [mostraModalVarBal, setMostraModalVarBal] = useState<boolean>(false)
    const [mostraModalGtinPadrao, setMostraModalGtinPadrao] = useState<boolean>(false)
    const [WMS, setWMS] = useState<number>(0)
    const [codigo, setCodigo] = useState<string>("")
    const [descricao, setDescricao] = useState<string>("")
    const [tipoPeso, setTipoPeso] = useState<string>("")
    const [CNPJ, setCNPJ] = useState<string>("")
    const [permiteAutorizarDivergencia, setPermiteAutorizarDivergencia] = useState<boolean>(false)
    const [usaValidade, setUsaValidade] = useState<boolean>(true)
    const [usaLote, setUsaLote] = useState<boolean>(true)

    const [mostraModalDivergencia, setMostraModalDivergencia] = useState<boolean>(false)

    const navigate = useNavigate()

    const token = getSessionData("MultfilialWebToken") ?? "";
    const idFilial = getSessionData("DadosIdfilial") ?? "";
    const usuarioLogado = getSessionData("NomeUsuario") ?? "";

    const sessionData = getSessionData("ProcessoEntrada");
    const sessionProcesso = sessionData ? sessionData.replace(/"/g, '') : '';

    // const editarUsuario = (dados: any) => { };
    // const excluirUsuario = () => { };
    // const visualizarTabelaOS = (dados: any) => { };

    function consultaProcessoEntrada(processo: any) {
        if (processo == " " || processo == 0 || processo == undefined) {
            toast.error("O valor do processo não pode ser 0")
            return;
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/entrada/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            // console.log(resposta)
            setSessionData("ProcessoEntrada", resposta.data.processo)
            consultaViewTable(resposta.data.processo)
            fecharModal()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data)
                setMostraModalSelecionaProcesso(true)
                setMostraModalLoading(false)
            }
        })
    }

    //Função para consultar conferencia e setar botão quando carrega a tela
    function consultaConferencia(processo: any) {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/conferencia/entrada/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            const conteudo = resposta.data[0].INICIO
            if (conteudo == null || conteudo == 0) {
                setBtnIniciar(false)
                setBtnConcluir(true)
            } else {
                setBtnIniciar(true)
                setBtnConcluir(false)
            }
        }).catch(function (erro) {
            console.error("Um erro inesperado aconteceu ao consultar conferência")
        })
    }

    function carregaGrid(processo: any, modalidade: any) {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/grid/entrada/${processo}/${modalidade}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setCNPJ(resposta.data.result[0].CLIENTE)
            if (modalidade == 'ARMAZENAGEM' || modalidade == 'DEVOLUÇÃO') {
                const newDados = (transformData(resposta.data.result))
                setDados(reduzData(newDados))
            } else {
                setDados(resposta.data.result)
            }

        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
        })
    }

    const reduzData = (data: any) => {
        return data.map((item: any) => {
            let descricao = item.descricao;
            if (descricao.length > 25) {
                descricao = descricao.slice(0, 25) + '...'
            }
            return { ...item, descricao }
        })
    }

    const transformData = (data: any) => {
        return data.map((item: any) => {
            let tipoPeso = item.TIPO_PESO;
            switch (tipoPeso) {
                case 'VARIADO_BALANÇA':
                    tipoPeso = 'VAR BALANÇA';
                    break;
                case 'VAR_ROM_MANUAL':
                    tipoPeso = 'VAR MANUAL';
                    break;
                case 'VARIADO_OPTICO':
                    tipoPeso = 'VAR OPTICO';
                    break;
                case 'PADRÃO':
                    tipoPeso = 'PADRÃO'
                    break
                default:
                    break;
            }
            return {
                ...item,
                TIPO_PESO: tipoPeso
            };
        });
    };


    async function consultaViewTable(processo: any) {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/view/table/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setModalidade(resposta.data.modalidade)
            if (resposta.data.modalidade == 'ARMAZENAGEM' || resposta.data.modalidade == 'DEVOLUÇÃO') {
                setViewTableArmDev(false)
                setViewTableCrossRe(true)
            } else {
                setViewTableArmDev(true)
                setViewTableCrossRe(false)
            }
            carregaGrid(processo, resposta.data.modalidade)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
        })
    }

    async function consultaConferirGtinPadrao(cnpj: string) {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/conferirGtin/${cnpj}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            if (resposta.data === true) {
                setMostraModalGtinPadrao(true)
            } else {
                setMostraModalPadrao(true)
                setMostraModalGtinPadrao(false)
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
        })
    }

    function iniciarConferencia() {
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/iniciar/conferencia/entrada?idFilial=${idFilial}`, {
            sessionProcesso
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data)
            setBtnIniciar(true)
            setBtnConcluir(false)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error("Não foi possível iniciar a conferencia")
            }
        })
    }

    function concluirConferencia() {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/concluir/conferencia/entrada?idFilial=${idFilial}`, {
            processo: sessionProcesso,
            usuarioLogado
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data)
            sessionStorage.removeItem("ProcessoEntrada")
            navigate("/menu/menuPrincipal")
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 308) {
                toast.error(erro.response.data)
                sessionStorage.removeItem("ProcessoEntrada")
                navigate("/menu/menuPrincipal")
            } else if (status == 409) {
                toast.error(erro.response.data)
            } else {
                toast.error(erro.response.data)
            }
        })
    }

    function consultaPermissaoDivergencia() {
        const email = getSessionData("EmailUsuario") || ''
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/permissao/divergencia/${email}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setPermiteAutorizarDivergencia(resposta.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                setPermiteAutorizarDivergencia(false)
            }
        })
    }

    function limpaTabelaDivergencia() {
        axios.delete(`${process.env.REACT_APP_API_BASE_URL}/delete/infos/${sessionProcesso}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                console.info(erro.response.data)
            }
        })
    }

    async function verificaValidadeLoteParaModais(codigo: string) {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/validade/lote/${codigo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {   
            setUsaLote(resposta.data.data[0].CONTROLA_LOTE)
            setUsaValidade(resposta.data.data[0].CONTROLA_VALIDADE)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
            else if (status === 406) {
                console.info(erro.response.data)
            }
        })
    }

    const colunasTableArmDev: ITabela[] = [
        { titulo: "Código", acesso: "codigo" },
        { titulo: "Descrição", acesso: "descricao" },
        { titulo: "Tipo", acesso: "TIPO_PESO" }
    ];

    const colunasTableCrossRe: ITabela[] = [
        { titulo: "NF", acesso: "NF" },
        { titulo: "Destinatário", acesso: "destinario" },
        { titulo: "Vol", acesso: "VOL" }
    ];

    function fecharModal() {
        setMostraModalSelecionaProcesso(false)
        setMostraModalBotoes(false)
        setMostraModalPadrao(false)
        setMostraModalVarOP(false)
        setMostraModalVarRomManual(false)
        setMostraModalVarBal(false)
        setMostraModalDivergencia(false)
    }

    function fecharModalGtin() {
        setMostraModalGtinPadrao(false)
    }

    useEffect(() => {
        consultaPermissaoDivergencia()
        if (sessionProcesso != "" && sessionProcesso) {
            setMostraModalSelecionaProcesso(false)
            consultaConferencia(sessionProcesso)
            consultaViewTable(sessionProcesso)
        } else {
            setMostraModalSelecionaProcesso(true)
        }
    }, [])

    const handleRowClick = (dados: any) => {
        setWMS(dados.ID_WMS)
        setCodigo(dados.codigo)
        setTipoPeso(dados.TIPO_PESO)
        setDescricao(dados.descricao)

        verificaValidadeLoteParaModais(dados.codigo)
        limpaTabelaDivergencia()
        if (dados.TIPO_PESO == "PADRÃO") {
            consultaConferirGtinPadrao(dados.CLIENTE)

        } else if (dados.TIPO_PESO == "VAR OPTICO") {
            setMostraModalVarOP(true)
        } else if (dados.TIPO_PESO == "VAR MANUAL") {
            setMostraModalVarRomManual(true)
        } else {
            setMostraModalVarBal(true)
        }
    }

    const onRowClick = () => { }

    return (
        <>
            <Card className="mt-2">
                <Card.Body>
                    {/* <div className="row mb-2 border rounded border-fkl py-1 d-flex justify-content-between align-items-center">
                        <div className="col-6">
                            <label className="m-0"><i>Processo: {sessionProcesso}</i></label>
                        </div>
                        <div className="col-6">
                            <button 
                            className="w-100 btn text-light bg-primary border border-dark rounded" 
                            onClick={() => setMostraModalSelecionaProcesso(true)}
                            >
                                <i className="bi bi-arrow-clockwise"></i> Trocar Processo
                            </button>
                        </div>
                    </div> */}
                    <div className="row mb-3">
                        <div className="col-6">
                            <button
                                type="button"
                                hidden={btnIniciar}
                                className="w-100 btn btn-danger btn-block rounded"
                                onClick={iniciarConferencia}
                            >
                                Iniciar
                            </button>
                            <button
                                type="button"
                                hidden={btnConcluir}
                                // hidden={true}
                                className="w-100 btn btn-cancelar btn-block rounded"
                                onClick={concluirConferencia}
                            >
                                Concluir
                            </button>
                        </div>

                        <div className="col-6">
                            {/* <button type="button" className='w-100 btn btn-cancelar btn-block rounded'>Concluir</button> */}
                            <button
                                type="button"
                                className='w-100 btn text-light bg-primary border border-dark btn-block rounded'
                                onClick={() => setMostraModalBotoes(true)}
                            >
                                Ações
                            </button>
                        </div>
                    </div>
                    {/* <Row hidden={viewTableArmDev}>
                        <Tabela
                            coluna={colunasTableArmDev}
                            dados={dados}
                            itemsPerPage={10}
                            onVisuClick={visualizarTabelaOS}
                            onEditClick={editarUsuario}
                            onDeleteClick={excluirUsuario}
                            id="codigo"
                            permissao_editar={permissao_editar}
                            permissao_visualizar={permissao_visualizar}
                            permissao_excluir={permissao_excluir}
                            viewAcoesConfSaida={true}
                        />
                    </Row> */}

                    {/* <Row className="mt-1" hidden={viewTableArmDev}>
                        <ClickableTable
                            coluna={colunasTableArmDev}
                            itemsPerPage={10}
                            data={dados}
                            onRowClick={handleRowClick}
                            viewFilter={false}
                        />
                    </Row> */}

                    {
                        viewTableArmDev && (
                            <>
                                <ClickableTable
                                    coluna={colunasTableCrossRe}
                                    itemsPerPage={10}
                                    data={dados}
                                    onRowClick={onRowClick}
                                // labelpesquisa="Pesquise pelo código"
                                />
                            </>
                        )
                    }
                    {
                        !viewTableArmDev && (
                            <>
                                <ClickableTable
                                    coluna={colunasTableArmDev}
                                    itemsPerPage={10}
                                    data={dados}
                                    onRowClick={handleRowClick}
                                    labelpesquisa="Pesquise pelo código"
                                    acessopesquisa="codigo"
                                />
                            </>
                        )
                    }



                    {/* <Row hidden={viewTableCrossRe}>
                        <Tabela
                            coluna={colunasTableCrossRe}
                            dados={dados}
                            itemsPerPage={10}
                            onVisuClick={visualizarTabelaOS}
                            onEditClick={editarUsuario}
                            onDeleteClick={excluirUsuario}
                            id="codigo"
                            permissao_editar={permissao_editar}
                            permissao_visualizar={permissao_visualizar}
                            permissao_excluir={permissao_excluir}
                            viewAcoesConfSaida={true}
                        />
                    </Row> */}

                    {/* <Row hidden={viewTableCrossRe}>
                        <ClickableTable
                            coluna={colunasTableCrossRe}
                            itemsPerPage={10}
                            data={dados}
                            onRowClick={onRowClick}
                            viewFilter={true}
                        />
                    </Row> */}
                </Card.Body>
            </Card>

            <ModalLoading show={mostralModalLoading} />

            <ModalBotoes
                isOpen={mostraModalBotoes}
                fecharModal={fecharModal}
                processo={sessionProcesso}
                tipoConferencia="Entrada"
                tipoOperacao={modalidade}
                permiteDivergencia={permiteAutorizarDivergencia}
            />

            <ModalSelecionaProcesso
                isOpen={MostraModalSelecionaProcesso}
                consultaProcesso={consultaProcessoEntrada}
                idFilial={idFilial}
                token={token}
                consultaConferencia={consultaConferencia}
                fecharModal={fecharModal}
            />

            <ModalGtinPadrao
                isOpen={mostraModalGtinPadrao}
                fecharModal={fecharModalGtin}
                WMS={WMS}
                token={token}
                idFilial={idFilial}
                CNPJ={CNPJ}
                setMostraModalPadrao={setMostraModalPadrao}
            />

            <ModalPadrao
                isOpen={mostraModalPadrao}
                fecharModal={fecharModal}
                processo={parseInt(sessionProcesso)}
                WMS={WMS}
                codigo={codigo}
                tipoPeso={tipoPeso}
                descricao={descricao}
                token={token}
                idFilial={idFilial}
                usuarioLogado={usuarioLogado}
                usaLote={usaLote}
                usaValidade={usaValidade}
            />

            <ModalVarOP
                isOpen={mostraModalVarOP}
                fecharModal={fecharModal}
                processo={parseInt(sessionProcesso)}
                WMS={WMS}
                codigo={codigo}
                tipoPeso={tipoPeso}
                descricao={descricao}
                idFilial={idFilial}
                token={token}
                usuarioLogado={usuarioLogado}
                usaValidade={usaValidade}
                usaLote={usaLote}
            />

            <ModalVarRomManual
                isOpen={mostraModalVarRomManual}
                fecharModal={fecharModal}
                processo={parseInt(sessionProcesso)}
                WMS={WMS}
                codigo={codigo}
                tipoPeso={tipoPeso}
                descricao={descricao}
                idFilial={idFilial}
                token={token}
                usuarioLogado={usuarioLogado}
                usaValidade={usaValidade}
                usaLote={usaLote}
            />

            <ModalVarBal
                isOpen={mostraModalVarBal}
                fecharModal={fecharModal}
                processo={parseInt(sessionProcesso)}
                WMS={WMS}
                codigo={codigo}
                tipoPeso={tipoPeso}
                descricao={descricao}
                token={token}
                idFilial={idFilial}
                usuarioLogado={usuarioLogado}
                usaValidade={usaValidade}
                usaLote={usaLote}
            />
        </>

    )
}

export default PagPrincipalEntrada