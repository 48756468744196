import axios from "axios";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { BsBoxSeam } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { showConfirmationDialog } from "../../../../components/Formularios/Modal/ModalExcluir";
import { FaPlus } from "react-icons/fa";
import { LiaPalletSolid } from "react-icons/lia";
import ClickableTable from "../../../../components/ClickTable/ClickTable";
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface";
import { IoReturnUpForward } from "react-icons/io5";

//Interface para receber as informações por parametros
interface ModalDivergenciaProps {
    isOpen: boolean;
    fecharModal: () => void;
    processo: any;
    idFilial: string;
    token: string;
    usuarioLogado: string;
    permissaoDivergencia: boolean;
}

const ModalDivergencia: React.FC<ModalDivergenciaProps> = ({
    isOpen,
    fecharModal,
    processo,
    idFilial,
    token,
    usuarioLogado,
    permissaoDivergencia
}) => {
    const [data, setData] = useState([])
    const [motivo, setMotivo] = useState<string>()

    const navigate = useNavigate();

    function consultaInfosGrid() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/infos/divergencia/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setData(resposta.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.info(erro.response.data)
            }
        })
    }

    function aprovarDivergencia() {
        if (!motivo) {
            toast.info("Preencha o motivo para prosseguir com aprovação")
            return
        }

        axios.put(`${process.env.REACT_APP_API_BASE_URL}/aprovar/divergencia?idFilial=${idFilial}`, {
            processo,
            motivo,
            usuarioLogado
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data)
            fecharModal()
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.info(erro.response.data)
            }
        })
    }

    const colunas: ITabela[] = [
        { titulo: "Cod", acesso: "codigo" },
        { titulo: "Descrição", acesso: "descricao" },
        { titulo: "Diferença", acesso: "dif" }
    ];

    return (
        <>
            <Modal className="mt-5" show={isOpen} onShow={() => {
                setData([])
                setMotivo("")
                consultaInfosGrid()
            }}>
                <Modal.Header className="bg-white" style={{ justifyContent: 'center' }}>
                    <Modal.Title style={{ flex: '1', textAlign: 'center', color: 'black' }}>{`${permissaoDivergencia ? "Aprovação de Divergência" : "Divergência"}`}</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={() => {
                            fecharModal()
                        }}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">

                    <Row className="justify-content-between" hidden={!permissaoDivergencia}>
                        <div className="col-5 form-floating mt-1">
                            <input
                                type="text"
                                autoFocus
                                value={motivo}
                                className="form-control bordasInferiorInput text-black tamanho-input"
                                onChange={(event) => setMotivo(event.target.value)}
                            />
                            <label className="form-label">MOTIVO</label>
                        </div>

                        <div className="col-7 mt-2">
                            <button
                                type="button"
                                className="w-100 btn btn-cancelar rounded fs-5"
                                onClick={() => aprovarDivergencia()}
                            >
                                <span className='me-2'>Aprovar divergência</span> <i className="bi bi-check-circle-fill"></i>

                            </button>
                        </div>
                    </Row>

                    <Row className="mt-3">
                        <ClickableTable
                            coluna={colunas}
                            itemsPerPage={10}
                            data={data}
                            onRowClick={function () { }}
                        />
                    </Row>

                </Modal.Body>

                {/* <Modal.Footer className="bg-white">


                </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default ModalDivergencia