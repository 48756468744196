import { Card, Row } from "react-bootstrap"
import Tabela from "../../../components/Formularios/Table"
import { useEffect, useState } from "react"
import { getSessionData } from "../../../utils/storageUtils"
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface"
import axios from "axios"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import ClickableTable from "../../../components/ClickTable/ClickTable"

function TelaPrincipalPicking() {
    const [permissao_editar, set_permissao_editar] = useState(false)
    const [permissao_excluir, set_permissao_excluir] = useState(true)
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)
    const [depositantes, setDepositantes] = useState<any>([])
    const [depositanteSelecionado, setDepositanteSelecionado] = useState<any>()
    const [dados, setDados] = useState([])

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")
    const usuarioLogado = getSessionData("NomeUsuario")

    const navigate = useNavigate()

    //Funções para controle de ações da tabela
    const editarUsuario = (dados: any) => {
        navigate(`/picking/pedidos/${dados.ROTA}`)
    };
    const excluirUsuario = (dados: any) => {};
    const visualizarTabelaOS = (dados: any) => { }

    //Criação das colunas da tabela
    const colunas: ITabela[] = [
        { titulo: "Vol", acesso: "QTD_CXS" },
        { titulo: "Pedido", acesso: "PEDIDO_CLIENTE" },
        { titulo: "Data", acesso: "DATA" },
        { titulo: "Rota", acesso: "ROTEIRO"}
    ];

    function carregaDepositante() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/depositante?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            // console.log(resposta)
            setDepositantes(resposta.data)
        }).catch(function (erro) {
            console.log(erro)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                toast.info(erro.response.data)
            }
        })
    }

    function carregaGrid(depositanteSelecionado: any) {
        if (depositanteSelecionado == 'Selecione') {
            setDados([])
            return;
        }
        const novoDepositanteSelecionado = depositantes.find((depositante: any) => depositante.Fantasia === depositanteSelecionado);

        const cnpjDepositanteSelecionado = novoDepositanteSelecionado ? novoDepositanteSelecionado.CNPJ : '';

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/grid/${cnpjDepositanteSelecionado}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            // console.log(resposta.data)
            setDados(resposta.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            }
        })
    }

    const handleRowClick = (dados: any) => {
        navigate(`/picking/pedidos/${dados.ROTA}`)
    }

    useEffect(() => {
        carregaDepositante()
    }, [])

    return (
        <>
            <Card className="mt-2">
                <Card.Body>
                    <Row>
                        <div className="row mt-1">
                            <div className="col form-floating">
                                <select
                                    autoFocus
                                    className="form-select bordasInferiorInput text-black text-capitalize"
                                    onChange={(event) => {
                                        setDepositanteSelecionado(event.target.value)
                                        carregaGrid(event.target.value)
                                    }}
                                    // onKeyDown={(event) => handleKeyDown(event, odoresRef)}
                                    value={depositanteSelecionado}
                                >
                                    <option>Selecione</option>

                                    {depositantes.map((depositante: any) => (
                                        <option key={depositante.ID}>{depositante.Fantasia}</option>
                                    ))}
                                </select>
                                <label className="form-label">DEPOSITANTE</label>
                            </div>
                        </div>
                    </Row>

                    <Row className="mt-3">
                        {/* <Tabela
                            coluna={colunas}
                            dados={dados}
                            itemsPerPage={10}
                            onVisuClick={visualizarTabelaOS}
                            onEditClick={editarUsuario}
                            onDeleteClick={excluirUsuario}
                            id="codigo"
                            permissao_editar={permissao_editar}
                            permissao_visualizar={permissao_visualizar}
                            permissao_excluir={permissao_excluir}
                            viewAcoesConfSaida={false}
                        /> */}

                        <ClickableTable 
                        coluna={colunas}
                        itemsPerPage={10}
                        data={dados}
                        onRowClick={handleRowClick}
                        />
                    </Row>

                </Card.Body>
            </Card>
        </>
    )
}

export default TelaPrincipalPicking