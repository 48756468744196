import { Row, Col, Card } from "react-bootstrap";
import ContainerTitulo from "../../../components/Formularios/Container/ContainerTitulo";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";

import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import Tabela from "../../../components/Formularios/Table";

import { useEffect, useState } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { InterfaceUsuario } from "../../../types/UsuarioInterface";
import { toast } from "react-toastify";
import ApiGet from "../../../api/endPoints/useGet";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import { showConfirmationDialog } from "../../../components/Formularios/Modal/ModalExcluir";
import ApiDelete from "../../../api/endPoints/useDelete";
import { getSessionData } from "../../../utils/storageUtils";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

export function Permissao() {
  const token = getSessionData("MultfilialWebToken") || "";
  const isSuper = getSessionData("isPerfilSuper") || "";
  const navigate = useNavigate();
  const id_perfil = getSessionData("id_perfil")

  interface Action {
    icon: JSX.Element;
    name: string;
  }
  const actions: Action[] = [
    {
      icon: (
        <AddIcon
          onClick={() => navigate("/administrativo/permisao/permissao/novo")}
        />
      ),
      name: "Incluir",
    },
    {
      icon: (
        <SupervisorAccountIcon
          onClick={() => navigate("/menu/menuAdministrativo")}
        />
      ),
      name: "Menu Administrativo",
    }
    // { icon: <FileCopyIcon />, name: "Duplicar" },
    // { icon: <SaveIcon />, name: "Salvar" },
    // { icon: <PrintIcon />, name: "Imprimir" },
  ];

  const visualizar = (dados: any) => {
    const id_perfil = dados.id_perfil;
    navigate("/administrativo/permisao/permissao/visu/" + id_perfil);
  };

  const editar = (dados: any) => {
    const id_perfil = dados.id_perfil;
    navigate("/administrativo/permisao/permissao/editar/" + id_perfil);
  };

  const excluir = async (dados: any) => {
    const id_perfil = dados.id_perfil;
    await showConfirmationDialog(
      "Excluir Perfil/Permissão",
      `Tem certeza que deseja excluir o perfil \n ${dados.nome_perfil}?`,
      "question"
    )
      .then((result) => {
        if (result.confirmed) {
          const headers = {
            Authorization: token,
          };
          const dados = {
            url: "/perfil/excluir/:id_perfil",
            parametros: { id_perfil: id_perfil },
            headers: headers,
          };
          ApiDelete(dados)
            .then((retorno) => {
              toast.success(retorno.message);
              buscarDados();
            })
            .catch((erro) => {
              toast.error(
                `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
              );
            });
        }
      })
      .catch((erro) => {
        toast.error(
          `Erro ao excluir registro. Motivo: ${erro.response.data.message}`
        );
      });
  };

  const colunas: ITabela[] = [
    { titulo: "Código", acesso: "id_perfil" },
    { titulo: "Perfil", acesso: "nome_perfil" },
    { titulo: "Situação", acesso: "situacao" },
  ];

  //#region REALIZA REQUISIÇÃO PARA LISTAR DADOS DA TABELA
  const [dados, setDados] = useState<InterfaceUsuario[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const buscarDados = async () => {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: `/perfil/listar/${isSuper}`,
      parametros: {},
      headers: headers,
    };
    ApiGet(dados)
      .then((retorno) => {
        setDados(retorno);
        setLoading(false);
      })
      .catch((erro) => {
        setLoading(false);
        toast.error(`Erro ao listar perfil. Motivo: ${erro.message}`);
      });
  }

  //verifica permissão --- inicio
  const [permissao_visualizar, set_permissao_visualizar] = useState(true)
  const [permissao_incluir, set_permissao_incluir] = useState(true)
  const [permissao_editar, set_permissao_editar] = useState(true)
  const [permissao_excluir, set_permissao_excluir] = useState(true)


  async function CarregarPermissões(id_perfil: any) {
    setLoading(true);
    const headers = {
      Authorization: token,
    };
    const dados = {
      url: "/perfil/carregar/:idperfil",
      parametros: {
        idperfil: id_perfil,
      },
      headers: headers,
    };
    ApiGet(dados).then(function (response) {
      if (response[0].visualizar_perfil_permissao === 1) {
        set_permissao_visualizar(false)
      }
      if (response[0].incluir_perfil_permissao === 1) {
        set_permissao_incluir(false)
      }
      if (response[0].editar_perfil_permissao === 1) {
        set_permissao_editar(false)
      }
      if (response[0].excluir_perfil_permissao === 1) {
        set_permissao_excluir(false)
      }


    })
  }
  //verifica permissão --- fim

  // Função para realizar a busca
  const [busca, set_busca] = useState("")
  function Search(string: any) {
    // Converta a entrada para minúsculas para tornar a busca sem distinção entre maiúsculas e minúsculas
    let termoBusca = string.toLowerCase();

    if (termoBusca == "") {
      buscarDados()
    }
    else {
      // Use a função filter para encontrar as pessoas que correspondem à busca
      const resultados = dados.filter(function (info: any) {

        // Converta para minúsculas para a comparação sem distinção entre maiúsculas e minúsculas
        let buscando = info.nome_perfil.toLowerCase()

        // Verifique se contém o termo de busca
        return buscando.includes(termoBusca)

      })


      setDados(resultados)
    }
  }

  useEffect(function () {
    Search(busca)
  }, [busca])

  useEffect(() => {
    CarregarPermissões(id_perfil)
    buscarDados();
  }, []);
  //#endregion

  return (
    <>
      <div className="container-fluid mt-2">
        <ContainerTitulo titulo="Listagem de Perfil / Permissão" />

        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body>
                <Row>
                  <SpeedDial
                    hidden={permissao_incluir}
                    ariaLabel="SpeedDial basic example"
                    direction="left"
                    sx={{
                      right: 0,
                      position: "absolute",
                    }}
                    icon={<SpeedDialIcon />}
                  >
                    {actions.map((action) => (
                      <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                      />
                    ))}
                  </SpeedDial>
                </Row>
                <Row>
                  <div className="d-flex my-2 gap-1 ">
                    <Col sm={11}>
                      <InputSemBorda
                        name="permissao"
                        type="text"
                        value={busca}
                        onChange={set_busca}
                        classNameInputsSemBorda="form-control bordasInferiorInput"
                        placeholder=""
                        label="Procurar por perfil"
                      />
                    </Col>

                  </div>
                </Row>

                <Row>
                  <Tabela
                    coluna={colunas}
                    dados={dados}
                    itemsPerPage={10}
                    onVisuClick={visualizar}
                    onEditClick={editar}
                    onDeleteClick={excluir}
                    onBaixarClick={() => {}}
                    id="codigo"
                    permissao_editar={permissao_editar}
                    permissao_visualizar={permissao_visualizar}
                    permissao_excluir={permissao_excluir}
                    permissao_baixar={true}
                  />
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <ModalLoading
        show={loading}
        label="Carregando Listagem de Usuários"
      />
    </>
  );
}
