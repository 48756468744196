import axios from "axios";
import { Card, Modal, Row } from "react-bootstrap";
import { getSessionData } from "../../../../utils/storageUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import ApexChart from 'react-apexcharts'
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import ModalCarregando from "../../../../components/ModalCarregando/ModalCarregando";

function DadosGtinCargaAndamento() {

    const navigate = useNavigate()

    const token = getSessionData("MultfilialWebToken") ?? "";
    const idFilial = getSessionData("DadosIdfilial") ?? "";

    const [filterOS, setFilterOS] = useState('');
    const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)

    const [series, setSeries] = useState([
        {
            name: 'Quantidade',
            data: [],
        },
        {
            name: 'Lido',
            data: [],
        },
    ]);

    const [options, setOptions] = useState<ApexOptions>({
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true,
            },
            zoom: {
                enabled: true,
            },
        },
        colors: ['#1C4B9B', '#FFA500'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
                dataLabels: {
                    total: {
                        enabled: false,
                        style: {
                            fontSize: '12px',
                            fontWeight: 900,
                        },
                    },
                },
            },
        },
        xaxis: {
            type: 'category',
            categories: [],
        },
        legend: {
            position: 'right',
            offsetY: 40,
        },
        fill: {
            opacity: 1,
        },
    });

    async function buscaDadosGtinAndamento(filterOS: any) {
        setMostraModalCarregando(true)
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/dados/gtin/andamento?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            const dados = resposta.data.data

            const dadosFiltrados = filterOS
                ? dados.filter((item: any) => item.PROCESSO.toString() === filterOS)
                : dados;

            const qtde = dadosFiltrados.map((item: { qtde: number }) => item.qtde)
            const lido = dadosFiltrados.map((item: { lido: number }) => item.lido)
            const osCategoria = dadosFiltrados.map((item: { OS: number, PROCESSO: number, seq_carga: number }) =>
                `P${item.PROCESSO} |S${item.seq_carga} |OS${item.OS}`
            );

            setSeries([
                {
                    name: 'Quantidade',
                    data: qtde,
                },
                {
                    name: 'Lido',
                    data: lido,
                },
            ]);

            setOptions((prevOptions) => ({
                ...prevOptions,
                colors: ['#1C4B9B', '#FFA500'],
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: osCategoria,
                },
            }));

        }).catch(function (erro) {
            const status = erro.response.status
            if (status === 403 || status === 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        }).finally(function () {
            setMostraModalCarregando(false)
        })
    }

    useEffect(() => {
        buscaDadosGtinAndamento(filterOS)
    }, [])

    useEffect(() => {
        function atualizarDados() {
            buscaDadosGtinAndamento(filterOS);
        }

        const intervalId = setInterval(atualizarDados, 30000);

        // Limpa o intervalo quando o componente é desmontado
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <Card className="mt-3">
                <h3 className="m-3">Dados da Leitura de Gtin em Andamento</h3>
                <Card.Body className="bg-white">

                    <Row>
                        <div className="col-4">
                            <input
                                type="text"
                                className="bordasInferiorInput text-black"
                                placeholder="Digite o número do Processo."
                                value={filterOS}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        buscaDadosGtinAndamento(filterOS)
                                    }
                                }}
                                onChange={(e) => setFilterOS(e.target.value)}
                            />
                        </div>

                        <div className="col-4 d-flex align-items-center justify-content-start">
                            <button
                                type="button"
                                className="btn btn-novo mt-1 me-2"
                                onClick={() => buscaDadosGtinAndamento(filterOS)}
                            >
                                <span className='me-2'>Aplicar Filtro</span>
                                <i className="bi bi-search"></i>
                            </button>
                            <button
                                type="button"
                                className="btn btn-cancelar mt-1"
                                onClick={() => {
                                    buscaDadosGtinAndamento("")
                                    setFilterOS("")
                                }}
                            >
                                <span className='me-2'>Resetar Filtro</span>
                                <i className="bi bi-x-circle"></i>
                            </button>
                        </div>

                    </Row>

                    <Row className="mt-2">
                        <div id="chart">
                            <ReactApexChart options={options} series={series} type="bar" height={350} />
                        </div>
                        <div id="html-dist"></div>
                    </Row>

                </Card.Body>
            </Card>

            <ModalCarregando
                mensagem="Atualizando gráfico"
                mostrar={mostraModalCarregando}
            />
        </>
    )
}

export default DadosGtinCargaAndamento