import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import { getSessionData } from "../../../../../utils/storageUtils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface ModalCheckListProps {
    isOpen: boolean;
    fecharModal: () => void;
    idFilial: any;
    token: any
    processo: any
}

const ModalCheckList: React.FC<ModalCheckListProps> = ({
    isOpen,
    fecharModal,
    idFilial,
    token,
    processo
}) => {

    const [higieneSelecionada, setHigieneSelecionada] = useState<any>()
    const [odoresSelecionado, setOdoresSelecionado] = useState<any>()
    const [tempSelecionada, setTempSelecionada] = useState<any>()
    const [vedacaoSelecionada, setVedacaoSelecionada] = useState<any>()
    const [lampadaSelecionada, setLampadaSelecionada] = useState<any>()
    const [asseioSelecionado, setAsseioSelecionado] = useState<any>()
    const [uniformeSelecionado, setUniformeSelecionado] = useState<any>()
    const [contPragasSelecionado, setContPragasSelecionado] = useState<any>()
    const [qtdAvariaInterna, setQtdAvariaInterna] = useState<any>(0)
    const [qtdAvariaExterna, setQtdAvariaExterna] = useState<any>(0)
    const [qtdAvariaAmassado, setQtdAvariaAmassado] = useState<any>(0)
    const [qtdAvariaMolhado, setQtdAvariaMolhado] = useState<any>(0)
    const [repaletizacao, setRepaletizacao] = useState<any>(0)
    const [aplicacao, setAplicacao] = useState<any>(0)
    const [colagem, setColagem] = useState<any>(0)
    const [reEmbalagem, setReEmbalagem] = useState<any>(0)
    const [pallet, setPallet] = useState<any>(0)
    const [lacre, setLacre] = useState<string>()
    const [observacoes, setObservacoes] = useState<any>()
    const [tempInicio, setTempInicio] = useState<any>(0)
    const [tempMeio, setTempMeio] = useState<any>(0)
    const [tempFim, setTempFim] = useState<any>(0)
    const [viewBtn, setViewBtn] = useState(false)

    const higieneRef = useRef(null);
    const odoresRef = useRef(null);
    const tempRef = useRef(null);
    const vedacaoRef = useRef(null);
    const lampadaRef = useRef(null);
    const asseioRef = useRef(null);
    const uniformeRef = useRef(null);
    const contPragasRef = useRef(null);
    const avariaInternaRef = useRef(null);
    const avariaExternaRef = useRef(null);
    const avariaAmassadoRef = useRef(null);
    const avariaMolhadoRef = useRef(null);
    const repaletizacaoRef = useRef(null);
    const aplicacaoRef = useRef(null);
    const colagemRef = useRef(null);
    const reEmbalagemRef = useRef(null);
    const palletRef = useRef(null);
    const observacoesRef = useRef(null);
    const btnRef = useRef(null);
    const tempInicioRef = useRef(null);
    const tempMeioRef = useRef(null);
    const tempFimRef = useRef(null);
    const lacreRef = useRef(null);

    const usuarioLogado = getSessionData("NomeUsuario")

    const navigate = useNavigate();

    const handleKeyDown = (event: any, ref: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            ref.current?.focus();
        }
    };

    function consultaViewBtnTemps() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/view/temps/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            if (resposta.data[0].TEMPERATURA_PROCESSO == 0) {
                setViewBtn(true)
            } else {
                setViewBtn(false)
            }
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                console.log(erro)
            }
        })
    }

    function consultaCheckList() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/checklist/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setHigieneSelecionada(resposta.data.checkList[0].HIGIENE_E_LIMPEZA)
            setOdoresSelecionado(resposta.data.checkList[0].LIVRE_DE_ODORES)
            setTempSelecionada(resposta.data.checkList[0].TEMPERATURA_ADEQUADA)
            setVedacaoSelecionada(resposta.data.checkList[0].VEDACAO_EFICIENTE)
            setLampadaSelecionada(resposta.data.checkList[0].INTEGRIDADE_LAMPADAS)
            setAsseioSelecionado(resposta.data.checkList[0].ASSEIO_DO_MOTORISTA)
            setUniformeSelecionado(resposta.data.checkList[0].MOTORISTA_UNIFORMIZADO)
            setContPragasSelecionado(resposta.data.checkList[0].CONTROLE_DE_PRAGAS_VIGENTE)
            setQtdAvariaInterna(resposta.data.checkList[0].QTD_AVARIA_INTERNA)
            setQtdAvariaExterna(resposta.data.checkList[0].QTD_AVARIA_EXTERNA)
            setQtdAvariaAmassado(resposta.data.checkList[0].QTD_AVARIA_AMASSADO)
            setQtdAvariaMolhado(resposta.data.checkList[0].QTD_AVARIA_MOLHADO)
            setRepaletizacao(resposta.data.checkList[0].REPALETIZACAO)
            setAplicacao(resposta.data.checkList[0].REFORCO_FILME)
            setColagem(resposta.data.checkList[0].COLAGEM_DE_ETIQUETAS)
            setReEmbalagem(resposta.data.checkList[0].REEMBALAGEM)
            setPallet(resposta.data.checkList[0].PALLET)
            setObservacoes(resposta.data.checkList[0].OBSERVACAO)
            setTempInicio(resposta.data.temp[0].TEMP_INICIO)
            setTempMeio(resposta.data.temp[0].TEMP_MEIO)
            setTempFim(resposta.data.temp[0].TEMP_FIM)
        }).catch(function (erro) {
            console.info(erro.response.data)
        })
    }

    function cadastraCheckList() {

        if (higieneSelecionada == 'Selecione' ||
            odoresSelecionado == 'Selecione' ||
            tempSelecionada == 'Selecione' ||
            vedacaoSelecionada == 'Selecione' ||
            lampadaSelecionada == 'Selecione' ||
            asseioSelecionado == 'Selecione' ||
            uniformeSelecionado == 'Selecione' ||
            contPragasSelecionado == 'Selecione' ||
            qtdAvariaInterna == undefined ||
            qtdAvariaExterna == undefined ||
            qtdAvariaAmassado == undefined ||
            qtdAvariaMolhado == undefined ||
            repaletizacao == undefined ||
            aplicacao == undefined ||
            colagem == undefined ||
            reEmbalagem == undefined||
            pallet == undefined ||
            lacre == undefined ||
            lacre == ''
        ) {
            toast.info("Preencha todos os campos para prosseguir");
            return; // Retorna da função sem fazer a requisição
        }

        if (viewBtn == false) {
            if (
                tempInicio == undefined ||
                tempMeio == undefined ||
                tempFim == undefined ||
                tempInicio == 100 ||
                tempMeio == 100 ||
                tempFim == 100 ||
                tempInicio == '' ||
                tempMeio == '' ||
                tempFim == ''
            ) {
                toast.info("Obrigatório inserir temperatura")
                return;
            }
        }

        axios.post(`${process.env.REACT_APP_API_BASE_URL}/cadastra/checkList/${processo}?usuarioLogado=${usuarioLogado}&idFilial=${idFilial}`, {
            higieneSelecionada,
            odoresSelecionado,
            tempSelecionada,
            vedacaoSelecionada,
            lampadaSelecionada,
            asseioSelecionado,
            uniformeSelecionado,
            contPragasSelecionado,
            qtdAvariaInterna,
            qtdAvariaExterna,
            qtdAvariaAmassado,
            qtdAvariaMolhado,
            repaletizacao,
            aplicacao,
            colagem,
            reEmbalagem,
            pallet,
            tempInicio,
            tempMeio,
            tempFim,
            observacoes,
            lacre
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data)
            fecharModal()
        }).catch(function (erro) {
            toast.error(erro.response.data)
        })
    }

    // useEffect(() => {
    //     consultaCheckList()
    // }, [])

    function handleOnShow() {
        consultaCheckList()
        consultaViewBtnTemps()
    }

    return (
        <>
            <Modal className='mt-3' show={isOpen} onShow={handleOnShow}>
                <Modal.Header className="bg-white d-flex justify-content-between align-items-center">
                    <Modal.Title style={{ flex: '1', textAlign: 'center' }}>Check-List</Modal.Title>
                    <i
                        className="bi bi-x-circle"
                        style={{ fontSize: '2rem' }}
                        onClick={fecharModal}
                    ></i>
                </Modal.Header>
                <Modal.Body className="bg-white">
                    <div className="mb-1 text-center " style={{ backgroundColor: "#1c4b9b", color: "white", paddingTop: '5px' }}>
                        <label className="form-label" style={{ paddingTop: '5px' }}>
                            <strong>CONDIÇÕES E HIGIÊNE DO VEÍCULO</strong>
                        </label>
                    </div>
                    <div className="row mt-2">
                        <div className="col form-floating">
                            <select
                                autoFocus
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setHigieneSelecionada(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, odoresRef)}
                                ref={higieneRef}
                                value={higieneSelecionada}
                            >
                                <option>Selecione</option>
                                <option>C</option>
                                <option>N/C</option>
                                <option>N/A</option>
                            </select>
                            <label className="form-label">HIGIENE E LIMPEZA</label>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col form-floating">
                            <select
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setOdoresSelecionado(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, tempRef)}
                                ref={odoresRef}
                                value={odoresSelecionado}
                            >
                                <option>Selecione</option>
                                <option>C</option>
                                <option>N/C</option>
                                <option>N/A</option>
                            </select>
                            <label className="form-label">LIVRE DE ODORES</label>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col form-floating">
                            <select
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setTempSelecionada(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, vedacaoRef)}
                                ref={tempRef}
                                value={tempSelecionada}
                            >
                                <option>Selecione</option>
                                <option>C</option>
                                <option>N/C</option>
                                <option>N/A</option>
                            </select>
                            <label className="form-label">TEMPERATURA ADEQUADA</label>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col form-floating">
                            <select
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setVedacaoSelecionada(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, lampadaRef)}
                                ref={vedacaoRef}
                                value={vedacaoSelecionada}
                            >
                                <option>Selecione</option>
                                <option>C</option>
                                <option>N/C</option>
                                <option>N/A</option>
                            </select>
                            <label className="form-label">VEDAÇÃO EFICIENTE</label>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col form-floating">
                            <select
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setLampadaSelecionada(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, asseioRef)}
                                ref={lampadaRef}
                                value={lampadaSelecionada}
                            >
                                <option>Selecione</option>
                                <option>C</option>
                                <option>N/C</option>
                                <option>N/A</option>
                            </select>
                            <label className="form-label">INTEGRIDADE DAS LÂMPADAS</label>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col form-floating">
                            <select
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setAsseioSelecionado(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, uniformeRef)}
                                ref={asseioRef}
                                value={asseioSelecionado}
                            >
                                <option>Selecione</option>
                                <option>C</option>
                                <option>N/C</option>
                                <option>N/A</option>
                            </select>
                            <label className="form-label">ASSEIO DO MOTORISTA</label>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col form-floating">
                            <select
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setUniformeSelecionado(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, contPragasRef)}
                                ref={uniformeRef}
                                value={uniformeSelecionado}
                            >
                                <option>Selecione</option>
                                <option>C</option>
                                <option>N/C</option>
                                <option>N/A</option>
                            </select>
                            <label className="form-label">MOTORISTA UNIFORMIZADO</label>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col form-floating">
                            <select
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setContPragasSelecionado(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, avariaInternaRef)}
                                ref={contPragasRef}
                                value={contPragasSelecionado}
                            >
                                <option>Selecione</option>
                                <option>C</option>
                                <option>N/C</option>
                                <option>N/A</option>
                            </select>
                            <label className="form-label">CONTROLE DE PRAGAS VIGENTE</label>
                        </div>
                    </div>

                    <div className="mb-1 mt-3 text-center " style={{ backgroundColor: "#1c4b9b", color: "white", paddingTop: '5px' }}>
                        <label className="form-label" style={{ paddingTop: '5px' }}>
                            <strong>CONTROLE AVARIAS</strong>
                        </label>
                    </div>
                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="number"
                                value={qtdAvariaInterna}
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setQtdAvariaInterna(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, avariaExternaRef)}
                                ref={avariaInternaRef}
                            />
                            <label className="form-label">QTD AVARIA INTERNA</label>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="number"
                                value={qtdAvariaExterna}
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setQtdAvariaExterna(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, avariaAmassadoRef)}
                                ref={avariaExternaRef}
                            />
                            <label className="form-label">QTD AVARIA EXTERNA</label>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="number"
                                value={qtdAvariaAmassado}
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setQtdAvariaAmassado(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, avariaMolhadoRef)}
                                ref={avariaAmassadoRef}
                            />
                            <label className="form-label">QTD AVARIA AMASSADO</label>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="number"
                                value={qtdAvariaMolhado}
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setQtdAvariaMolhado(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, repaletizacaoRef)}
                                ref={avariaMolhadoRef}
                            />
                            <label className="form-label">QTD AVARIA MOLHADO</label>
                        </div>
                    </div>

                    <div className="mb-1 mt-3 text-center " style={{ backgroundColor: "#1c4b9b", color: "white", paddingTop: '5px' }}>
                        <label className="form-label" style={{ paddingTop: '5px' }}>
                            <strong>SERVIÇOS EXTRAS</strong>
                        </label>
                    </div>
                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="number"
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setRepaletizacao(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, aplicacaoRef)}
                                ref={repaletizacaoRef}
                                value={repaletizacao}
                            />
                            <label className="form-label">REPALETIZAÇÃO COM FILME</label>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="number"
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setAplicacao(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, colagemRef)}
                                ref={aplicacaoRef}
                                value={aplicacao}
                            />
                            <label className="form-label">APLICAÇÃO/REFORÇO FILME</label>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="number"
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setColagem(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, reEmbalagemRef)}
                                ref={colagemRef}
                                value={colagem}
                            />
                            <label className="form-label">COLAGEM DE ETIQUETAS (CX)</label>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="number"
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setReEmbalagem(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, palletRef)}
                                ref={reEmbalagemRef}
                                value={reEmbalagem}
                            />
                            <label className="form-label">RE-EMBALAGEM(CX)</label>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="number"
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setPallet(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, lacreRef)}
                                ref={palletRef}
                                value={pallet}
                            />
                            <label className="form-label">PALLET(UN)</label>
                        </div>
                    </div>

                    <div className="row mt-2">

                        <div className="col form-floating">
                            <input
                                type="text"
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setLacre(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, tempInicioRef)}
                                ref={lacreRef}
                                value={lacre}
                            />
                            <label className="form-label">LACRE</label>
                        </div>
                    </div>

                    <div className="row mt-2">

                        <div className="col form-floating" hidden={viewBtn}>
                            <input
                                type="text"
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                hidden={viewBtn}
                                onChange={(event) => {
                                    setTempInicio(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, tempMeioRef)}
                                ref={tempInicioRef}
                                value={tempInicio}
                            />
                            <label className="form-label">TEMP INÍCIO</label>
                        </div>
                    </div>

                    <div className="row mt-2">

                        <div className="col form-floating" hidden={viewBtn}>
                            <input
                                type="text"
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setTempMeio(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, tempFimRef)}
                                ref={tempMeioRef}
                                value={tempMeio}
                            />
                            <label className="form-label">TEMP MEIO</label>
                        </div>
                    </div>

                    <div className="row mt-2">

                        <div className="col form-floating" hidden={viewBtn}>
                            <input
                                type="text"
                                className="form-control bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setTempFim(event.target.value)
                                }}
                                onKeyDown={(event) => handleKeyDown(event, observacoesRef)}
                                ref={tempFimRef}
                                value={tempFim}
                            />
                            <label className="form-label">TEMP FIM</label>
                        </div>
                    </div>

                    <div className="mb-1 mt-3 text-center" style={{ backgroundColor: "#1c4b9b", color: "white", paddingTop: '5px' }}>
                        <label className="form-label" style={{ paddingTop: '5px' }}>
                            <strong>OBSERVAÇÕES GERAIS CHECK-LIST</strong>
                        </label>
                    </div>
                    <div className='form-floating'>
                        <textarea
                            className="form-control bordasInferiorInput text-black"
                            maxLength={255}
                            rows={14}
                            onChange={(event) => {
                                setObservacoes(event.target.value)
                            }}
                            onKeyDown={(event) => handleKeyDown(event, btnRef)}
                            ref={observacoesRef}
                            value={observacoes}
                        >
                        </textarea>
                        <label htmlFor="">OBSERVAÇÕES</label>
                    </div>
                </Modal.Body>
                <Modal.Footer className="bg-white">
                    <div className="mb-2">
                        <button
                            type="button"
                            className="btn btn-cancelar me-2"
                            data-dismiss="modal"
                            onClick={fecharModal}
                        >
                            Cancelar
                        </button>
                        <button
                            type="button"
                            className="btn btn-novo"
                            // onKeyDown={() => }
                            onClick={cadastraCheckList}
                            ref={btnRef}
                        >
                            <span className='me-1'><FaSave size={15} /></span>Salvar Check List
                        </button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default ModalCheckList