import { Modal } from "react-bootstrap";
import { getSessionData } from "../../../../../../utils/storageUtils";
import { useEffect, useState } from "react";
import ModalLoading from "../../../../../../components/Formularios/Modal/ModalLoading";
import { ModalSelecionaFilial } from "../../../../../administrativo/filial/components/ModalSelecionaClinicaLink/SelecionaClinica";

interface ModalSelecionarFilialLinkProps {
  abrir?: boolean;
  onClose: () => void;
  onclick?: (formData: any) => void;
}

const ModalSelecionarFilialLink: React.FC<ModalSelecionarFilialLinkProps> = ({
  abrir,
  onClose,
  onclick,
  //loadingFilial
}) => {
  const token = getSessionData("MultfilialWebToken") || "";

  const [loading, setLoading] = useState<boolean>(false);


  
  const capturar = (dadosFilial: any) => {
    if (onclick) onclick(dadosFilial);
  };
  return (
    <>
      <Modal
        show={abrir}
        // onHide={fecharModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
        className="modal-dialog-centered"
      >
        <ModalSelecionaFilial
          titulo="Selecione a Filial"
          onclick={capturar}
          onclickFechar={onClose}
        />
      </Modal>
      <ModalLoading show={loading} label="Carregando Filiais..." />
    </>
  );
};
export default ModalSelecionarFilialLink;
