import { useEffect, useState } from "react";
import { ITabela } from "../../../components/Formularios/Table/TabelaInterface";
import { useNavigate } from "react-router-dom";
import { Card, Row } from "react-bootstrap";
import Tabela from "../../../components/Formularios/Table";
import axios from "axios";
import { getSessionData, setSessionData } from "../../../utils/storageUtils";
import { toast } from "react-toastify";
import ModalLoading from "../../../components/Formularios/Modal/ModalLoading";
import ModalSelecionaProcesso from "../components/ModalSelecionaProcesso";
import ModalTabelaDeOS from "./components/ModalTabelaOS";
import { IoConstruct } from "react-icons/io5";
import ModalBotoes from "../components/ModalBotoes";
import InputSemBorda from "../../../components/Formularios/Inputs/InputsSemBorda";
import ModalConfirm from "./components/ModalConfirm";
import ClickableTable from "../../../components/ClickTable/ClickTable";
import { speak } from "../../../utils/notifySons"
import ModalCarregando from '../../../components/ModalCarregando/ModalCarregando';

function PagPrincipal() {
    //Variaveis de estado para controlar a aplicação
    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)
    const [permissao_visualizar, set_permissao_visualizar] = useState(false)
    const [permissao_baixar, setPermissaoBaixar] = useState(false)
    const [mostralModalLoading, setMostraModalLoading] = useState(false)
    const [dados, setDados] = useState([])
    const [mostraModalSelecionaProcesso, setMostraModalSelecionaProcesso] = useState(false);
    const [modalTabelaOS, setModalTabelaOS] = useState(false)
    const [modalBotoes, setModalBotoes] = useState(false)
    const [processo, setProcesso] = useState<any>()
    const [OS, setOS] = useState<any>()
    const [btnIniciar, setBtnIniciar] = useState(false)
    const [btnConcluir, setBtnConcluir] = useState(true)
    const [mostraModalConfirm, setMostraModalConfirm] = useState(false)
    const [osParaBaixa, setOsParaBaixa] = useState<any>()
    const [confirmaUABloqueada, setConfirmaUABloqueada] = useState(false)
    const [mostraModalCarregando, setMostraModalCarregando] = useState<boolean>(false)

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")
    const usuarioLogado = getSessionData("NomeUsuario")
    //Verificando se o processo está preenchido para retiras as aspas duplas
    const sessionData = getSessionData("Processo");
    const sessionProcesso = sessionData ? sessionData.replace(/"/g, '') : '';

    const navigate = useNavigate()

    //Funções para controle de ações da tabela
    const editarUsuario = (dados: any) => { };
    const excluirUsuario = () => { };
    const visualizarTabelaOS = (dados: any) => {
        setOS(dados.OS)
        setModalTabelaOS(true)
    };
    const BtnBaixarOS = (dados: any) => {
        setOsParaBaixa(dados.OS)
        verificaUABloqueada(dados.OS)
    }

    //Função para consultar processo quando inserido no modal
    function consultaProcesso(processo: any) {
        if (processo == " " || processo == 0 || processo == undefined) {
            toast.error("O valor do processo não pode ser 0")
            speak('Valor Não Pode Ser zero')
            return;
        }

        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/processo/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setSessionData("Processo", resposta.data)
            consultaProcessoGrid(resposta.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data)
                speak(erro.response.data)
                setMostraModalSelecionaProcesso(true)
                setMostraModalLoading(false)
            }
        })
    }

    //Função para consultar processo e carregar a tela
    function consultaProcessoGrid(processo: any) {
        setMostraModalLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/saida/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            if (resposta.data[0].OS != undefined) {
                setOS(resposta.data[0].OS)
            }
            setDados(resposta.data)
            setProcesso(resposta.data[0].PROCESSO)
            setMostraModalLoading(false)
            setMostraModalSelecionaProcesso(false)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                // toast.error(erro.response.data)
                setDados([])
                console.log(erro.response.data)
                setMostraModalSelecionaProcesso(false)
                setMostraModalLoading(false)
            }
        })
    }

    //Função para iniciar conferencia
    function iniciarConferencia() {
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/iniciar/conferencia?idFilial=${idFilial}`, {
            processo: processo ? processo : getSessionData("Processo")
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            toast.success(resposta.data)
            speak(resposta.data)
            setBtnIniciar(true)
            setBtnConcluir(false)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error("Ocorreu um erro inesperado ao tentar iniciar conferência")
                speak("Erro Inesperado")
                setBtnIniciar(false)
                setBtnConcluir(true)
            }
        })
    }

    //Função para concluir conferencia
    function concluirConferencia() {
        setMostraModalLoading(true)
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/concluir/conferencia?usuarioLogado=${usuarioLogado}&idFilial=${idFilial}`, { sessionProcesso }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setMostraModalLoading(false)
            //console.log(resposta)
            toast.success(resposta.data)
            speak(resposta.data)
            setBtnConcluir(true)
            setBtnIniciar(false)
            sessionStorage.removeItem("Processo")
            navigate("/menu/menuPrincipal")
        }).catch(function (erro) {
            setMostraModalLoading(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data)
                speak(erro.response.data)
                setBtnIniciar(true)
                setBtnConcluir(false)
            }
        })
    }

    //Função para consultar conferencia e setar botão quando carrega a tela
    function consultaConferencia(processo: any) {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/conferencia/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            const conteudo = resposta.data[0].INICIO
            if (conteudo == null || conteudo == 0) {
                setBtnIniciar(false)
                setBtnConcluir(true)
            } else {
                setBtnIniciar(true)
                setBtnConcluir(false)
            }
        }).catch(function (erro) {
            console.error("Um erro inesperado aconteceu ao consultar conferência")
        })
    }


    //Função para fechar modal 
    function fecharModal() {
        setModalTabelaOS(false)
        setModalBotoes(false)
        setMostraModalConfirm(false)
        setMostraModalSelecionaProcesso(false)
    }

    //Criação das colunas da tabela
    const colunas: ITabela[] = [
        { titulo: "Seq", acesso: "seq_carga" },
        { titulo: "O.S.", acesso: "OS" },
        { titulo: "Cliente", acesso: "Fantasia" },
        { titulo: "Rota", acesso: "ROTA" },
    ];

    function baixarOS(OSBaixa: any) {
        setMostraModalCarregando(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/baixar/OS?idFilial=${idFilial}`, {
            OS: OSBaixa,
            usuarioLogado,
            processo
        }, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setMostraModalCarregando(false)
            toast.success(resposta.data)
            speak(resposta.data)
            consultaProcessoGrid(sessionProcesso)
        }).catch(function (erro) {
            setMostraModalCarregando(false)
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                toast.error(erro.response.data)
            } else if (status == 308) {
                if (erro.response.data == "Ler Gtin") {
                    toast.info("É necessário realizar a leitura de Gtin")
                    speak("Obrigatório Ler Gtin")
                    navigate(`/conferencia/lerGtin/${OSBaixa}/${sessionProcesso}`)
                } else if (erro.response.data == "Ler Volumes") {
                    toast.info("É necessário realizar a leitura de volumes")
                    speak("Obrigatório Ler Volumes")
                    navigate(`/conferencia/lerVolumes/${OSBaixa}/${sessionProcesso}`)
                } else {
                    toast.info("É necessário realizar a pesagem das U.A")
                    speak("Obrigatório Pesagem das U.A.")
                    navigate(`/conferencia/pesagemUA/${OSBaixa}`)
                }
            }

            consultaProcessoGrid(sessionProcesso)
        })
    }

    function verificaUABloqueada(OS: any) {
        setOsParaBaixa(OS)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/verifica/UA/${OS}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            baixarOS(OS)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else if (status == 406) {
                setMostraModalConfirm(true)
                speak("Atenção. Existe U.A. Bloqueado")
            }
        })
    }


    //Verificando se o processo está guardado no sessionStorage
    //Se o processo não estiver guardado apresenta o modal para inserção do número 
    useEffect(() => {
        if (sessionProcesso != "" && sessionProcesso) {
            setMostraModalSelecionaProcesso(false)
            consultaProcessoGrid(sessionProcesso)
            consultaConferencia(sessionProcesso)
        } else {
            setMostraModalSelecionaProcesso(true)
        }
    }, [])

    return (
        <>
            <Card className="mt-2">
                <Card.Body>
                    {/* <div className="row mb-2 border rounded border-fkl py-1 d-flex justify-content-between align-items-center">
                        <div className="col-6">
                            <label className="m-0"><i>Processo: {sessionProcesso}</i></label>
                        </div>
                        <div className="col-6">
                            <button 
                            className="w-100 btn text-light bg-primary border border-dark rounded" 
                            onClick={() => setMostraModalSelecionaProcesso(true)}
                            >
                                <i className="bi bi-arrow-clockwise"></i> Trocar Processo
                            </button>
                        </div>
                    </div> */}
                    <div className="row mb-3">
                        <div className="col-6">
                            <button
                                type="button"
                                hidden={btnIniciar}
                                className="w-100 btn btn-danger btn-block rounded"
                                onClick={iniciarConferencia}
                            >
                                Iniciar
                            </button>
                            <button
                                type="button"
                                hidden={btnConcluir}
                                className="w-100 btn btn-cancelar btn-block rounded"
                                onClick={concluirConferencia}
                            >
                                Concluir
                            </button>
                        </div>
                        <div className="col-6">
                            {/* <button type="button" className='w-100 btn btn-cancelar btn-block rounded'>Concluir</button> */}
                            <button
                                type="button"
                                className='w-100 btn text-light bg-primary border border-dark btn-block rounded'
                                onClick={() => setModalBotoes(true)}
                            >
                                Ações
                            </button>
                        </div>
                        <ModalCarregando mensagem="Carregando..." mostrar={mostraModalCarregando} />
                    </div>
                    {/* <Row>
                        <Tabela
                            coluna={colunas}
                            dados={dados}
                            itemsPerPage={10}
                            onVisuClick={visualizarTabelaOS}
                            onEditClick={editarUsuario}
                            onDeleteClick={excluirUsuario}
                            onBaixarClick={BtnBaixarOS}
                            id="codigo"
                            permissao_editar={permissao_editar}
                            permissao_visualizar={permissao_visualizar}
                            permissao_excluir={permissao_excluir}
                            permissao_baixar={permissao_baixar}
                            viewAcoesConfSaida={false}
                        />
                    </Row> */}

                    <Row>
                        <ClickableTable
                            coluna={colunas}
                            data={dados}
                            itemsPerPage={10}
                            onRowClick={function () { }}
                            usaAcoes={true}
                            usaEditar={false}
                            usaVisualizar={true}
                            usaBaixar={true}
                            acaoVisualizar={visualizarTabelaOS}
                            acaoBaixar={BtnBaixarOS}
                            viewAcoesConfSaida={false}
                            usaExcluir={false}
                            labelpesquisa={"Pesquise pela O.S."}
                            acessopesquisa="OS"
                        />
                    </Row>
                    {/* <button
                        className="mt-3 btn text-light bg-primary border border-dark btn-lg btn-block w-100"
                        onClick={() => setModalBotoes(true)}
                    >
                        Ações
                    </button> */}
                </Card.Body>
            </Card>
            <ModalSelecionaProcesso
                isOpen={mostraModalSelecionaProcesso}
                consultaProcesso={consultaProcesso}
                idFilial={idFilial}
                token={token}
                consultaConferencia={consultaConferencia}
                fecharModal={fecharModal}
            />
            <ModalTabelaDeOS
                isOpen={modalTabelaOS}
                fecharModal={fecharModal}
                OS={OS}
                idFilial={idFilial}
                token={token}
            />
            <ModalBotoes
                isOpen={modalBotoes}
                fecharModal={fecharModal}
                processo={sessionProcesso}
                OS={OS}
                tipoConferencia="Saida"
            />
            <ModalConfirm
                isOpen={mostraModalConfirm}
                fecharModal={fecharModal}
                // setConfirmaUABloqueada={setConfirmaUABloqueada}
                onConfirm={baixarOS}
                OSBaixa={osParaBaixa}
            />
            <ModalLoading show={mostralModalLoading} />
        </>
    )
}

export default PagPrincipal