import axios from 'axios';
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

//Interface para receber as informações nos parametros 
interface ModalSelecionaOSProps {
  isOpen: boolean;
  consultaProcesso: (processo: any) => void;
  idFilial: any;
  token: any;
  consultaConferencia: (processo: any) => void;
  fecharModal: () => void;
}

//Criação do componente
const ModalSelecionaProcesso: React.FC<ModalSelecionaOSProps> = ({
  isOpen,
  consultaProcesso,
  idFilial,
  token,
  consultaConferencia,
  fecharModal
}) => {

  //Variáveis de estado para uso na aplicação
  const [processo, setProcesso] = useState<any>()
  const navigate = useNavigate()

  return (
    <>
      {/* Controle de visualização do modal */}
      <Modal className='mt-5'
        show={isOpen}>
        {/* Topo do modal com título do objetivo do mesmo*/}
        <Modal.Header className="bg-white">
          <Modal.Title>{"Insira o número do processo "}</Modal.Title>
        </Modal.Header>
        {/* Corpo do modal com os inputs para cadastro de novo equipamento */}
        <Modal.Body className="bg-white">
          <div className='row align-items-end justify-content-center'>
            <div className='col-10 form-floating mt-1'>
              <input
                type="search"
                autoFocus
                placeholder='Procure aqui...'
                className='form-control bordasInferiorInput text-black w-100'
                onChange={(event) => {
                  setProcesso(event.target.value)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    consultaProcesso(processo)
                    consultaConferencia(processo)
                  }
                }}
              />
              <label htmlFor="">Buscar por processo</label>
            </div>
            <div className='col-10 mt-3'>
              <button
                type="button"
                className='mb-2 w-100 text-center btn btn-cancelar btn-block rounded'
                onClick={() => {
                  consultaProcesso(processo)
                  consultaConferencia(processo)
                  fecharModal()
                }}
              >
                Pesquisar
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* Parte de baixo do modal com os botões */}
        <Modal.Footer className="bg-white">
          <button
            type="button"
            className="btn btn-outline-secondary btn-sm"
            onClick={() => navigate("/menu/menuPrincipal")}
          >
            Cancelar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )

}

export default ModalSelecionaProcesso
