import { Card, Col, Row } from "react-bootstrap"
import { ITabela } from "../../../../components/Formularios/Table/TabelaInterface"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import { getSessionData } from "../../../../utils/storageUtils"
import { toast } from "react-toastify"
import Tabela from "../../../../components/Formularios/Table"
import { useEffect, useRef, useState } from "react"
import { IoMdArrowBack } from "react-icons/io"
import generatePDF, { Margin } from 'react-to-pdf';
import logo from "../../../../assets/img/logo_sm.png"
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import JsBarcode from 'jsbarcode';

function GerarImpressao() {

    const [permissao_editar, set_permissao_editar] = useState(true)
    const [permissao_excluir, set_permissao_excluir] = useState(true)
    const [permissao_visualizar, set_permissao_visualizar] = useState(true)
    const [permissao_baixar, setPermissaoBaixar] = useState(false)
    const [dados, setDados] = useState([])
    const [UA, setUA] = useState<number>()
    const [cliente, setCliente] = useState<string>()
    const [validade, setValidade] = useState<string>()
    const [codigo, setCodigo] = useState<string>()
    const [lote, setLote] = useState<number>()
    const [pb, setPb] = useState<number>()
    const [pl, setPl] = useState<number>()
    const [temp, setTemp] = useState<string>()
    const [qtde, setQtde] = useState<number>()

    const navigate = useNavigate()
    const params = useParams()
    const processo = params.processo

    const idFilial = getSessionData("DadosIdfilial")
    const token = getSessionData("MultfilialWebToken")

    const targetRef = useRef(null);
    const barcodeRef = useRef(null);

    const editarUsuario = (dados: any) => { };
    const excluirUsuario = () => { };
    const visualizarTabelaOS = (dados: any) => { };

    const BtnBaixarOS = (dados: any) => {
        setCliente(dados.CLIENTE)
        setCodigo(dados.CODIGO)
        setUA(dados.PALLET)
        setLote(dados.LOTE)
        setPb(dados.PB)
        setPl(dados.PL)
        setQtde(dados.QUANTIDADE)
        setTemp(dados.TEMPERATURA)

        const validade = new Date(dados.VALIDADE)
        let validadeUA = validade.toISOString().substring(0, 10).replace(/-/g, '/')
        let [year, month, day] = validadeUA.split('/')
        setValidade(`${day}/${month}/${year}`)

        JsBarcode(barcodeRef.current, dados.PALLET, {
            format: 'CODE128',
            displayValue: true,
            fontSize: 14,
            width: 1.5, // ajuste a largura da barra
            height: 30, // ajuste a altura da barra
        });

        const generatePDF = async () => {
            const element = targetRef.current;
            if (!element) return;

            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'landscape',
                unit: 'cm',
                format: [5.32, 10.20],
            });

            pdf.addImage(imgData, 'PNG', 0, 0, 10.20, 5.32);
            pdf.save(`U.A.${dados.PALLET}.pdf`);
        };

        setTimeout(generatePDF, 100);

    };

    const colunas: ITabela[] = [
        { titulo: "U.A.", acesso: "PALLET" },
        { titulo: "Código", acesso: "CODIGO" },
        { titulo: "Qtde", acesso: "QUANTIDADE" }
    ]

    function consultaGrid() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/consulta/grid/${processo}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDados(resposta.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                console.log(erro)
            }
        })
    }

    useEffect(() => {
        consultaGrid()
    }, [])

    return (
        <>
            <Card className="mt-2">
                <Card.Body>

                    <Row>
                        <div className="col-2 mt-1">
                            <IoMdArrowBack
                                style={{ color: 'DarkOrange' }}
                                size={28}
                                onClick={() => navigate(-1)}
                            />
                        </div>
                    </Row>

                    <Row className="mt-2">
                        <Tabela
                            coluna={colunas}
                            dados={dados}
                            itemsPerPage={10}
                            onVisuClick={visualizarTabelaOS}
                            onEditClick={editarUsuario}
                            onDeleteClick={excluirUsuario}
                            onBaixarClick={BtnBaixarOS}
                            id="codigo"
                            permissao_editar={permissao_editar}
                            permissao_visualizar={permissao_visualizar}
                            permissao_excluir={permissao_excluir}
                            permissao_baixar={permissao_baixar}
                            viewAcoesConfSaida={false}
                        />
                    </Row>

                    <div>
                        <div style={{ position: 'absolute', left: '-9999px', width: '10cm', height: '5cm', padding: '0.5cm', border: '1px solid #000' }} ref={targetRef}>
                            <Row className="align-items-center">
                                <Col xs="2">
                                    <img src={logo} alt="logo" className="img-fluid" style={{ maxWidth: '100%', height: 'auto' }} />
                                </Col>
                                <Col xs="auto">
                                    <p className="mb-0 text-dark font-weight-bold">{cliente}</p>
                                </Col>
                                <Col xs="auto">
                                    <p className="mb-0">
                                        <span style={{ fontWeight: 'bold' }}>Processo:</span> {processo}
                                    </p>
                                </Col>
                            </Row>

                            {/* <Row className="text-center">
                                <p className="mb-1 text-dark">{UA}</p>
                            </Row> */}

                            <Row className="text-center">
                                <Col>
                                    <p className="mb-0">
                                        <span style={{ fontWeight: 'bold' }}>Validade:</span> {validade}
                                    </p>
                                </Col>

                                <Col>
                                    <svg ref={barcodeRef}></svg>
                                </Col>

                                <Col>
                                    <p className="mb-0">
                                        <span style={{ fontWeight: 'bold' }}>Lote:</span> {lote}
                                    </p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="mb-0">
                                        <span style={{ fontWeight: 'bold' }}>PL:</span> {pl}
                                    </p>
                                </Col>

                                <Col>
                                    <p className="mb-0">
                                        <span style={{ fontWeight: 'bold' }}>Cód:</span> {codigo}
                                    </p>
                                </Col>

                                <Col>
                                    <p className="mb-0">
                                        <span style={{ fontWeight: 'bold' }}>PB:</span> {pb}
                                    </p>
                                </Col>
                            </Row>

                            <Row className="mt-1">
                                <Col>
                                    <p className="mb-0">
                                        <span style={{ fontWeight: 'bold' }}>Qtde:</span> {qtde}
                                    </p>
                                </Col>
                                <Col>
                                    <p className="mb-0">
                                        <span>{temp}</span>
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </div>

                </Card.Body>
            </Card>
        </>

    )
}

export default GerarImpressao